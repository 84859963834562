import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewReviewPeriodComponent } from './view-review-period.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { ViewReviewPeriodRoutingModule } from './view-review-period-routing.module';
import { AuthInterceptor } from 'src/app/_helpers/auth.interceptor';
import { ErrorInterceptor } from 'src/app/_helpers/error.interceptor';
import { ReviewService } from 'src/app/services/review/review.service';
import { DepartmentService } from 'src/app/services/department/department.service';
import { DatePipe } from '@angular/common';
import { Select2Module } from "ng-select2-component";


@NgModule({
  declarations: [
    ViewReviewPeriodComponent
  ],
  imports: [
    ViewReviewPeriodRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // MatDatepickerModule
    NgxDaterangepickerMd.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1150,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation:"increasing",
      resetTimeoutOnDuplicate: true
    }),
    // BrowserAnimationsModule,
    DataTablesModule,
    Select2Module
  ],
  providers: [
    ReviewService,DepartmentService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
})
export class ViewReviewPeriodModule { }
