import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {

  constructor(
    private http: HttpClient
  ) { }

  // updateEditPrivilege(data) {
  //   let privilegeUpdate = this.http.put(`${baseUrl}/privilege/update-edit-status`);
  //   return privilegeUpdate;
  // }

  getAllObjectives(){
    let objectiveData = this.http.get(`${baseUrl}/objective`);
    return objectiveData;
  }
  
  updateEditPrivilege(data) {
    let roleData = {
      role: data,
    }
    let objData = this.http.post(`${baseUrl}/privilege/update-edit-status`, roleData);
    return objData;
  }
  
  getObjective(myId){
    const params = new HttpParams()
        .set('objectiveId', myId) 
    let objectiveData = this.http.get(`${baseUrl}/objective/objectiveId`, {params: params});
    return objectiveData
  }
  addObjectiveTitle(data){
    let objectiveData = this.http.post(`${baseUrl}/objective/points`, data);
    return objectiveData;
  }
  getStaffEditStatus(){
    let objectiveData = this.http.get(`${baseUrl}/privilege/get-staff-edit-status`);
    return objectiveData;
  }
  
}
