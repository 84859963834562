import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ReviewService } from 'src/app/services/review/review.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-initiated-review',
  templateUrl: './initiated-review.component.html',
  styleUrls: ['./initiated-review.component.css']
})
export class InitiatedReviewComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject<any>();

  userObject: any;

  constructor(
    private reviewService: ReviewService,
    private router : Router
  ) { 
    this.userObject = JSON.parse(localStorage.getItem('loggedInUser') );
  }
  allReviewPeriods: [] = []

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.getInitiatedReview()
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    // $.fn.dataTable.ext.search.pop();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      // this.dtTrigger.next();
    });
  }

  getInitiatedReview = ()=> {
    this.reviewService.getUserReview().subscribe((data)=>{
      this.allReviewPeriods = JSON.parse(JSON.stringify(data)).data;
      this.dtTrigger.next();
    })
  }
  clickedViewStatus(reviewId) {
    if((this.userObject.role == "staff") || (this.userObject.role == "manager") ){
      this.router.navigate([`user/final-review/${reviewId}`]);
    } else if(this.userObject.role == "admin") {
      this.router.navigate([`final-review/${reviewId}`])
    }
  }

  selfReviewClicked(reviewId){
    if((this.userObject.role == "staff") || (this.userObject.role == "manager") ){
      this.router.navigate([`user/self-review/${reviewId}`]);
    } else if(this.userObject.role == "admin") {
      this.router.navigate([`self-review/${reviewId}`])
    }
  }

}
