import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(
    private http: HttpClient
  ) { }

  getAllReviewTitles() {
    let allReviewPeriods = this.http.get(`${baseUrl}/reviewPeriod`);
    return allReviewPeriods;
  }
  getUserReview() {
    let allReviewPeriods = this.http.get(`${baseUrl}/reviewPeriod/user`);
    return allReviewPeriods;
  }
  createReview(data) {
    let createReviewperiod = this.http.post(`${baseUrl}/reviewPeriod`, data);
    return createReviewperiod;
  }
  updateReviewLasttDate(data) {
    let createReviewperiod = this.http.put(`${baseUrl}/reviewPeriod/lastDate`, data);
    return createReviewperiod;
  }
  initiateReview(data) {
    let reviewData = this.http.post(`${baseUrl}/reviewPeriod/initateReview`, data);
    return reviewData;
  }

  /** Get Individual Reviews  */
  getIndividualReviews(id) {
    let reviewData = this.http.get(`${baseUrl}/review/${id}`);
    return reviewData;
  }
  updateSelfReview(data) {
    let updateData = this.http.post(`${baseUrl}/review/self`,data );
    return updateData;
  }
  updateManagerReview(data) {
    let updateData = this.http.post(`${baseUrl}/review/manager`,data );
    return updateData;
  }
  closeSelfReview(data) {
    let updateData = this.http.post(`${baseUrl}/review/self/complete`, data);
    return updateData;
  }
  closeManagerReview(data) {
    let updateData = this.http.post(`${baseUrl}/review/manager/complete`, data);
    return updateData;
  }
  userReports(userId, startDate, endDate) {
    let reportData = this.http.put(`${baseUrl}/review/user/${userId}`,{ startDate: startDate, endDate: endDate});
    return reportData;
  };

  getReviewUsers(reviewPeriod) {
    let responseData = this.http.post(`${baseUrl}/review/allUsers`,{reviewPeriod:reviewPeriod});
    return responseData;
  }
  reviewCompletedUsers(reviewPeriod) {
    let responseData = this.http.post(`${baseUrl}/review/allReviewedUsers`,{reviewPeriod:reviewPeriod});
    return responseData;
  };
  reviewNotCompletedUsers(reviewPeriod) {
    let responseData = this.http.post(`${baseUrl}/review/notReviewedUsers`,{reviewPeriod:reviewPeriod});
    return responseData;
  };
  closeReview(reviewPeriod) {
    let responseData = this.http.put(`${baseUrl}/reviewPeriod/closed`,{reviewPeriod: reviewPeriod});
    return responseData;
  }
  reOpenReview(reviewPeriod) {
    let responseData = this.http.put(`${baseUrl}/reviewPeriod/reOpen`,{reviewPeriod: reviewPeriod});
    return responseData;
  };
  updateReviewPeriodCount() {
    let responseData = this.http.get(`${baseUrl}/reviewPeriod/counts`);
    return responseData;
  }
  updateReviewManager(data) {
    let responseData = this.http.put(`${baseUrl}/review/updateManager`, data );
    return responseData;
  }

  deleteReview(review) {
    let responseData = this.http.put(`${baseUrl}/review/delete`, review );
    return responseData;
  }


    updateManager(upData) {
    let updateData = this.http.put(`${baseUrl}/review/updateManager`, upData);
    return updateData;
}

}
