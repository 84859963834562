import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-router-directive',
  templateUrl: './router-directive.component.html',
  styles: []
})
export class RouterDirectiveComponent implements OnInit {
  userSubject: any;

  constructor(
    private router : Router
   ) {
    this.userSubject = JSON.parse(localStorage.getItem('loggedInUser') );
   }


  ngOnInit(): void {
    if(this.userSubject){
      if ( this.userSubject.role == "admin" ) {
        this.router.navigate(['home']);
      } else if ( ( this.userSubject.role == "manager") || ( this.userSubject.role == "staff" ) ) {
        this.router.navigate(['user'])
      }
    } else {
      this.router.navigate(['login'])
    }

  }

}
