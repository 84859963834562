import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObjectiveService } from 'src/app/services/objective/objective.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
   styleUrls: ['./indicators.component.css']
})
export class IndicatorsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private objectiveService: ObjectiveService
  ) { }
  objectiveDetails: any;
  objTitleArray: any;
  objectiveKey: any;
  goalTitle: any;

  ngOnInit(): void {
    this.route.params.subscribe((params)=>{
      this.objectiveKey = params.objectiveId;
    })
    if (this.objectiveKey) {
      this.loadParamObjectiveData(this.objectiveKey)
    } else {
      this.loadObjectiveData()
    }
  }

  loadObjectiveData = ()=> {
    this.objectiveService.getUserObjective().subscribe((doc)=> {
      let objDetails = JSON.parse(JSON.stringify(doc));
      if (objDetails.message === "Success") {
        this.objectiveDetails = objDetails.data;
        // this.currentObjective = this.objectiveDetails._id;
        this.objTitleArray = (this.objectiveDetails[0].objectives && this.objectiveDetails[0].objectives.length) ? this.objectiveDetails[0].objectives : [];
        if (this.objTitleArray.length) {
          this.objTitleArray.forEach((ele)=>{
            let total = 0;
            if(ele.goals && ele.goals.length){
              ele.goals.forEach((goal)=>{
                total = total + JSON.parse(goal.score);
              })
            }
            ele.totalScore = total;
          })
        }
      }
    })
  }
  loadParamObjectiveData = (objId)=> {
    this.objectiveService.getObjective(objId).subscribe((doc)=> {
      let objDetails = JSON.parse(JSON.stringify(doc));
      this.goalTitle = JSON.parse(JSON.stringify(doc)).data.designation.title + ' - ' + JSON.parse(JSON.stringify(doc)).data.department.title;      
      if (objDetails.message === "Success") {
        this.objectiveDetails = objDetails.data;

        // this.currentObjective = this.objectiveDetails._id;
        this.objTitleArray = (this.objectiveDetails.objectives && this.objectiveDetails.objectives.length) ? this.objectiveDetails.objectives : [];
        if (this.objTitleArray.length) {

          this.objTitleArray.forEach((ele)=>{
            let total = 0;
            if(ele.goals && ele.goals.length){
              ele.goals.forEach((goal)=>{
                total = total + JSON.parse(goal.score);
              })
            }
            ele.totalScore = total;
          })
        }
      }
    })
  }

}
