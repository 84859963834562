
<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Goals - [<b>{{ goalTitle }} </b>]</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <a href="#">Home</a>
                        </li>
                        <li class="breadcrumb-item active">Goals</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="card card-info card-outline col-12"  *ngFor="let obj of objTitleArray; let i=index">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-file-alt"></i>&nbsp;&nbsp;
                        <b>{{obj.title}}</b>
                      </h3>
                      <span class="btn btn-sm btn-outline-info float-right" style="color: black !important;cursor:default;">&nbsp; <b>{{obj.totalScore}} Points</b></span>

                    </div>
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th style="width: 10px">#</th>
                                <th>Goals</th>
                                <th style="width: 40px">Points</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let goal of obj.goals;let indexOfelement=index;">
                                <td>{{indexOfelement+1}}</td>
                                <td>{{goal.title}}</td>
                                <td>{{goal.score}}</td>
                            </tr>
                            </tbody>
                          </table>
                    </div>
                    <!-- /.card -->
                  </div>

            </div>
        </div>
        <!-- /.modal-delete starts -->


        <!-- /.modal-goals ends -->
    </section>
</div>