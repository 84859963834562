import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveService {

  constructor(
    private http: HttpClient
  ) { }
  createObjective(data){
    let objectiveData = this.http.post(`${baseUrl}/objective`, data);
    return objectiveData;
  }
  getAllObjectives(){
    let objectiveData = this.http.get(`${baseUrl}/objective`);
    return objectiveData;
  }
  getAllObjectivesByDepartment(depId) {
    const params = new HttpParams()
        .set('departmentId', depId) 
    let objectiveData = this.http.get(`${baseUrl}/objective/get-objectives-by-departmentId`, {params: params});
    return objectiveData
  }
  getAllObjectivesByDepartmentAndDesignation(data) {
    console.log(data);
    const params = new HttpParams()
        .set('departmentId', data.depId).set('designationId', data.designId) 
    let objectiveData = this.http.get(`${baseUrl}/objective/get-objectives-by-departmentId-and-designationId`, {params: params});
    return objectiveData
  }
  getAllCompletedObjectives(){
    let objectiveData = this.http.get(`${baseUrl}/objective/completed`);
    return objectiveData;
  }
  importGoals(data) {
    let objData = this.http.put(`${baseUrl}/objective/import-goals`, data);
    return objData;
  }
  getUserObjective() {
    let objectiveData = this.http.get(`${baseUrl}/objective/user`);
    return objectiveData
  }
  getObjective(myId){
    const params = new HttpParams()
        .set('objectiveId', myId) 
    let objectiveData = this.http.get(`${baseUrl}/objective/objectiveId`, {params: params});
    return objectiveData
  }
  addObjectiveTitle(data){
    let objectiveData = this.http.post(`${baseUrl}/objective/points`, data);
    return objectiveData;
  }
  addGoals(data) {
    let goalData = this.http.post(`${baseUrl}/objective/goals`, data);
    return goalData;
  }
  deleteObjective(data) {
    let objData = this.http.put(`${baseUrl}/objective/deleteObjectives`, data);
    return objData;
  }
  deleteGoal(data){
    let objData = this.http.put(`${baseUrl}/objective/deleteGoals`, data);
    return objData
  }
  updateObjective(data) {
    let updateResp = this.http.put(`${baseUrl}/objective/points`, data)
    return updateResp;
  }
  updateGoal(data) {
    let updateResp = this.http.put(`${baseUrl}/objective/goals`, data)
    return updateResp;
  }
  deleteFullObjective(objectiveId) {
    let responseData = this.http.put(`${baseUrl}/objective/delete`,{objectiveId:objectiveId} )
    return responseData;
  }
  objectiveDesignation(departmentIds){
    let objectiveData = this.http.post(`${baseUrl}/objective/designations`, { departmentIds: departmentIds });
    return objectiveData
  }
  
}
