import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// import { LoginComponent } from './layouts/login/login.component';
import { LoginModule } from './pages/login/login.module';
import { AppHeaderComponent } from './_layouts/app-header/app-header.component';
import { AppFooterComponent } from './_layouts/app-footer/app-footer.component';
import { AppSidebarComponent } from './_layouts/app-sidebar/app-sidebar.component';
import { AppLayoutComponent } from './_layouts/app-layout/app-layout.component';
// import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MemberSidebarComponent } from './_layouts/member-sidebar/member-sidebar.component';
import { MemberLayoutComponent } from './_layouts/member-layout/member-layout.component';
import { MemberComponent } from './pages/member/member.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListIndicatorsModule } from './pages/list-indicators/list-indicators.module'
import { CreateIndicatorModule } from './pages/create-indicator/create-indicator.module';
import { IndicatorsModule } from './pages/indicators/indicators.module';
// import { ReviewPeriodModule } from './pages/review-period/review-period.module';
import { ViewReviewPeriodModule } from './pages/review-period/view-review-period/view-review-period.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor} from './_helpers/auth.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { SelfReviewModule } from './pages/self-review/self-review.module';
import { ManagerReviewModule } from './pages/manager-review/manager-review.module';
import { FinalReviewModule } from './pages/final-review/final-review.module';
import { InitiatedReviewModule } from './pages/initiated-review/initiated-review.module';
import { MemberModule } from './pages/member/member.module';
import { RouterDirectiveComponent } from './pages/router-directive/router-directive.component';
import { RegistrationModule } from './pages/registration/registration.module';
// import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AppSidebarComponent,
    AppLayoutComponent,
    // DashboardComponent,
    MemberSidebarComponent,
    MemberLayoutComponent,
    // MemberComponent,
    RouterDirectiveComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    ListIndicatorsModule,
    CreateIndicatorModule,
    IndicatorsModule,
    // ReviewPeriodModule,
    ViewReviewPeriodModule,
    SelfReviewModule,
    ManagerReviewModule,
    FinalReviewModule,
    InitiatedReviewModule,
    MemberModule,
    RegistrationModule,
    FormsModule,
    ReactiveFormsModule,
    // DataTablesModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
