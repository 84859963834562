<div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto login-div-padding">
    <div class="card card0 border-0">
        <div class="row d-flex">
            <div class="col-lg-6">
                <div class="card1 pb-5 mt-3">
                    <div class="row px-3"> <img src="../../../assets/pictures/wac.png" class="logo"> </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line"> <img
                            src="https://i.imgur.com/uNGdWHi.png" class="image"> </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5">
                    <div class="row mb-4 px-3">
                        <h6 class="mb-0 mr-4 mt-2">Sign in with</h6>
                        
                    </div>
                    <div class="row px-3 mb-4">
                        <div class="line"></div> <small class="or text-center"></small>
                        <div class="line"></div>
                    </div>
                    <form [formGroup]="registerForm" (ngSubmit)="login()">
                        <div class="row px-3 mb-4"> <label class="mb-1">
                                <h6 class="mb-0 text-sm">Email Address</h6>
                            </label>
                            <input type="text" id="email" name="email" placeholder="Enter a valid email address"  formControlName="email" class="form-control"
                                [ngClass]="{'is-invalid':submitted && f.email.errors}" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required </div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid one </div>
                                </div>
                        </div>
                        <div class="row px-3"> <label class="mb-1">
                                <h6 class="mb-0 text-sm">Password</h6>
                            </label>
                            <input type="password"  name="password" placeholder="Enter password" id="password" formControlName="password" class="form-control"
                                [ngClass]="{'is-invalid':submitted && f.password.errors}" />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength">Minimum length is 4</div>
                                </div>
                        </div>
                        <div class="row px-3 mb-4">
                            <div class="custom-control custom-checkbox custom-control-inline"> 
                                <input id="chk1" type="checkbox" name="chk" class="custom-control-input"> 
                                <!-- <label for="chk1" class="custom-control-label text-sm">Remember me</label>  -->
                                </div> <a href="#" data-toggle="modal" data-target="#modal-calender" class="text-danger ml-auto mb-0 text-sm">Forgot Password?</a>
                        </div>
                        <div class="row mb-3 px-3"> 
                            <button type="submit" class="btn btn-blue text-center">Login</button>
                        </div>
                    </form>


                    <!-- <div class="row mb-4 px-3"> <small class="font-weight-bold">Don't have an account ? 
                        <a href="/register" class="text-danger " (click)="signupPrss()">Register</a></small> 
                    </div> -->
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-calender" #closebutton aria-hidden="true" style="display: none;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reset Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf= "!newPasswordField">
                            <form [formGroup]="codeGenerateForm">
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label><h6> Enter registered E-mail Address: </h6></label>
                                                <input class="form-control" type="text" name="registeredEmail" id="registeredEmail" placeholder="Enter registered email"
                                                    formControlName="registeredEmail" [ngClass]="{ 'is-invalid': generateCodePress && g.registeredEmail.errors }" required>
                                                    <div *ngIf="generateCodePress && g.registeredEmail.errors" class="invalid-feedback">
                                                        <div *ngIf="g.registeredEmail.errors.required">Email is required </div>
                                                        <div *ngIf="g.registeredEmail.errors.email">Email must be a valid one </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <button type="button" class="btn btn-primary mb-4" (click)="generateCode(codeGenerateForm.value)">Generate reset code</button>
                        </div>

                        <div *ngIf="newPasswordField">
                            <form [formGroup]="resetPasswordForm">
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label><h6> Enter reset code recieved in your E-mail: </h6></label>
                                                <input class="form-control" type="text" name="resetCode" id="resetCode" placeholder="Enter reset code recieved"
                                                    formControlName="resetCode" [ngClass]="{ 'is-invalid': resetPasswordSubmit && h.resetCode.errors }" required>
                                                    <div *ngIf="resetPasswordSubmit && h.resetCode.errors" class="invalid-feedback">
                                                        <div *ngIf="h.resetCode.errors.required">Reset code required </div>
                                                    </div>
                                            </div>
                                            <div class="form-group">
                                                <label><h6> Enter new password: </h6></label>
                                                <input class="form-control" type="password" name="newPassword" id="newPassword" placeholder="Enter a new password"
                                                    formControlName="newPassword" [ngClass]="{ 'is-invalid': resetPasswordSubmit && h.newPassword.errors }" required>
                                                    <div *ngIf="resetPasswordSubmit && h.newPassword.errors" class="invalid-feedback">
                                                        <div *ngIf="h.newPassword.errors.required">Password is required</div>
                                                        <div *ngIf="h.newPassword.errors.minlength">Minimum length is 4</div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                           <button type="button" class="btn btn-primary" (click)="resetPassword(resetPasswordForm.value)">Save changes</button>
                        </div>
  
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        <!-- <button type="button" class="btn btn-primary" (click)="createReview(registerForm.value)">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-blue py-4">
            <div class="row px-3"> <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; 2021. All rights reserved.</small>
                <div class="social-contact ml-4 ml-sm-auto"> <span class="fa fa-facebook mr-4 text-sm"></span> <span
                        class="fa fa-google-plus mr-4 text-sm"></span> <span class="fa fa-linkedin mr-4 text-sm"></span>
                    <span class="fa fa-twitter mr-4 mr-sm-5 text-sm"></span> </div>
            </div>
        </div>
    </div>
</div>
