import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styles: [
  ]
})
export class AppHeaderComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  resetPasswordSubmit: Boolean = false;
  resetPasswordForm: FormGroup;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private userSerivces: UserService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      resetCode: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(4)]]
    });
  };

  get h() {
    return  this.resetPasswordForm.controls;
  }

  logoutUser(){
    this.authService.logout()
  };

  generateResetCode() {
    this.userSerivces.generateResetCode().subscribe((result)=>{
      let responseData = JSON.parse(JSON.stringify(result));
      this.toastr.info(responseData.message);
    },(err)=> {
      this.toastr.warning(err, 'Error');
    })

  };

  resetPassword(formData) {
    this.resetPasswordSubmit = true;
    if (this.resetPasswordForm.invalid) {
      return
    } else {
      this.userSerivces.userUpdatePassword(formData).subscribe((result)=> {
        this.closebutton.nativeElement.click();
        let responseData = JSON.parse(JSON.stringify(result));
        if (responseData && responseData.message){
          this.toastr.success(responseData.message, 'Success');
        }
      },(err)=> {
        this.toastr.warning(err, 'Error');
      })
    }
  }

};
