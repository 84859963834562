
<div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto login-div-padding">
    <div class="card card0 border-0">
        <div class="row d-flex">
            <div class="col-lg-6">
                <div class="card1 pb-5 mt-3">
                    <div class="row px-3"> <img src="../../../assets/pictures/wac.png" class="logo"> </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line"> <img
                            src="https://i.imgur.com/uNGdWHi.png" class="image"> </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5 mb-3">
                    <div class="row mb-1 px-3">
                        <h6 class="mb-0 mr-4 mt-0">Sign Up</h6>
                        
                    </div>
                    <div class="row px-3 mb-4">
                        <div class="line"></div> <small class="or text-center"></small>
                        <div class="line"></div>
                    </div>
                    <form [formGroup]="registerForm" (ngSubmit)="signUp(registerForm)">
                        <div class="formDiv">
                            <div class="row px-3"> 
                                <label class="mb-1">
                                    <h6 class="mb-0 text-sm">Name</h6>
                                </label> 
                                <input type="text" id="name" name="name" class="form-control mb-4" formControlName="name"
                                    [ngClass]="{'is-invalid':submitted && f.name.errors}" placeholder="Enter name" />
                                    <div *ngIf="submitted && f.name.errors?.required" class="invalid-feedback mb-4">Name is required
                                        <!-- <div *ngIf="f.name.errors.required">Name is required </div> -->
                                    </div>
                            </div>
                            <div class="row px-3"> 
                                <label class="mb-1">
                                    <h6 class="mb-0 text-sm">Employee Id</h6>
                                </label> 
                                <input type="text" id="employeeId" name="employeeId"  formControlName="employeeId" class="form-control mb-4" 
                                    [ngClass]="{'is-invalid':submitted && f.employeeId.errors}"  placeholder="Enter employee id" />
                                    <div *ngIf="submitted && f.employeeId.errors" class="invalid-feedback">
                                        <div *ngIf="f.employeeId.errors.required">Employee Id is required </div>
                                    </div>
                            </div>
                            <div class="row px-3"> 
                                <label class="mb-1">
                                    <h6 class="mb-0 text-sm">Email Address</h6>
                                </label> 
                                <input type="text" id="email" name="email"  formControlName="email" class="form-control mb-4" 
                                    [ngClass]="{'is-invalid':submitted && f.email.errors}" placeholder="Enter a valid email address" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required </div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid one </div>
                                    </div>
                            </div>
                            <div class="row px-3"> 
                                <label class="mb-1">
                                    <h6 class="mb-0 text-sm">Department</h6>
                                </label> 
                                <select class="form-control mb-4" formControlName="department" id="department" name="department"
                                    (ngModelChange)="onChangeDept($event)" [ngClass]="{'is-invalid':submitted && f.department.errors}">
                                    <option class="defSelect" value="" selected>Select Department</option>
                                    <option  *ngFor="let dept of departmentData" [ngValue]="dept._id" >{{dept.title}}</option>
                                </select>
                                <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                                    <div *ngIf="f.department.errors.required">Employee Department is required </div>
                                </div>
                            </div>
                            <div class="row px-3"> 
                                <label class="mb-1">
                                    <h6 class="mb-0 text-sm">Designation</h6>
                                </label> 
                                <select class="form-control mb-4" formControlName="designation" id="designation" name="designation"
                                    [ngClass]="{'is-invalid':submitted && f.designation.errors}">
                                    <option class="defSelect" value="" selected>Select Designation</option>
                                    <option  *ngFor="let desig of designationArray" [ngValue]="desig._id" >{{desig.title}}</option>
                                </select>
                                <div *ngIf="submitted && f.designation.errors" class="invalid-feedback">
                                    <div *ngIf="f.designation.errors.required">Employee Designation is required </div>
                                </div>
                            </div>
                            <div class="row px-3"> 
                                <label class="mb-1">
                                    <h6 class="mb-0 text-sm">Role</h6>
                                </label> 
                                <!-- <input type="text" id="role" name="role" placeholder="Enter employee role"  formControlName="role" class="mb-4"
                                    [ngClass]="{'is-invalid':submitted && f.role.errors}" />
                                    <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                                        <div *ngIf="f.role.errors.required">Employee Role is required </div>
                                    </div> -->
                                <select class="form-control mb-4" formControlName="role" id="role" name="role"
                                    [ngClass]="{'is-invalid':submitted && f.role.errors}">
                                    <option class="defSelect" value="" selected>Select Role</option>
                                    <option  *ngFor="let role of roleArray" [ngValue]="role.value" >{{role.key}}</option>
                                </select>
                                <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                                    <div *ngIf="f.role.errors.required">Employee Role is required </div>
                                </div>
                            </div>
                            <div class="row px-3"> 
                                <label class="mb-1">
                                    <h6 class="mb-0 text-sm">Password</h6>
                                </label> 
                                <input type="password"  name="password" id="password" formControlName="password" class="form-control mb-4" 
                                    [ngClass]="{'is-invalid':submitted && f.password.errors}" placeholder="Enter password" />
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <br>
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength">Minimum length is 4</div>
                                    </div>
                            </div>
                            <br>
                            <!-- <div class="row px-3 mb-4">
                                
                            </div> -->
                        </div>
                        <div class="row mb-3 mt-2 px-3"> 
                            <button type="submit" class="btn btn-blue text-center">Register</button>
                        </div>
                    </form>
   
                    <div class="row mb-1 px-3"> <small class="font-weight-bold">Already have an account ? <a
                                class="text-danger " (click)="loginPrss()">Login</a></small> </div>
                </div>
            </div>
        </div>
        <div class="bg-blue py-4">
            <div class="row px-3"> <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; 2021. All rights reserved.</small>
                <div class="social-contact ml-4 ml-sm-auto"> <span class="fa fa-facebook mr-4 text-sm"></span> <span
                        class="fa fa-google-plus mr-4 text-sm"></span> <span class="fa fa-linkedin mr-4 text-sm"></span>
                    <span class="fa fa-twitter mr-4 mr-sm-5 text-sm"></span> </div>
            </div>
        </div>
    </div>
</div>


