import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(
    private http: HttpClient
  ) { };
  
  getAllDepartments(){
    let allDepartments = this.http.get(`${baseUrl}/department`);
    return allDepartments;
  }

  createDepartment(body){
    let respData = this.http.post(`${baseUrl}/department`, body);
    return respData;
  }


}
