<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Objectives</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Objectives</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>

    <section class="content">

        <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Objectives</h3>

                <div class="card-tools">
                    <div class="btn-group">
                        <button type="button" class="btn btn-success" title="Create Objective" data-toggle="modal" data-target="#modal-create">
                        <i class="fas fa-bullseye"></i> Create Objectives
                    </button> 
                    <span >
                        &nbsp; &ensp;
                        <select *ngIf="userSubject.role == 'admin'" class="btn btn-outline-dark" (change)="onChangeDeptFilter($event)" aria-hidden="true">
                            <option value="" >Filter By Department</option>    
                            <option *ngFor="let deptFilter of departmentArray"  [value]="deptFilter._id">{{deptFilter.title}}</option>
                        </select>
                        &nbsp;
                        &ensp;<select *ngIf="userSubject.role == 'admin'" class="btn btn-outline-dark" (change)="onChangeDesignFilter($event)" aria-hidden="true">
                            <option value="" >Filter By Designation</option>    
                            <option *ngFor="let deptFilter of designationArray" [value]="deptFilter._id">{{deptFilter.title}}</option>
                        </select>
                    </span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="tb-employee-list" class="table table-striped projects table-bordered table-hover dataTable dtr-inline table-responsive-xl p-1" role="grid" aria-describedby="example2_info">
                    <thead>
                        <tr>
                            <th style="width: 1%">
                                #
                            </th>
                            <th style="width: 20%">
                                Designation
                            </th>
                            <th class="text-center">
                                Goals Updated
                            </th>
                            <th class="text-center" style="width: 20%">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="odd" *ngFor="let objective of objectivesList; let i=index">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                <a *ngIf="objective.designation !== null">
                                    {{objective.designation.title}}
                                </a>
                                <a *ngIf="objective.designation == null">
                                    NA
                                </a>
                                <br>
                                <small>
                                    {{objective.department.title}}
                                </small>
                            </td>
                            <td class="project-state text-align">
                                <span *ngIf="objective.totalScore !== 100" class="badge badge-warning"> Pending </span>
                                <span *ngIf="objective.totalScore == 100" class="badge badge-success">Completed </span>
                            </td>

                            <td class="project-actions text-center">

                                <div class="btn-group">
                                    <!-- <button onclick="location.href=`/indicators/{{objective._id}}`" type="button" class="btn btn-info"><i
                                            class="fas fa-folder"> -->
                                <button *ngIf="objective.totalScore !== 100"  (click)="importGoalTo(objective._id)" type="button" class="btn btn-lg btn-outline-info" title="Import" data-toggle="modal" data-target="#modal-assign">
                                    <i class="fa fa-file-import"> </i>  </button> 
                                    <button (click)="navigateIndicators(objective._id)" type="button" title="View" class="btn btn-outline-info ml-1">
                                        <i class="fas fa-folder"> </i>  </button> 
                                    <!-- <button onclick="location.href='/create-goals'" type="button" class="btn btn-info"><i class="fas fa-pencil-alt"> -->
                                    <span *ngIf="userSubject.role == 'manager'">
                                        <button *ngIf="managerEditPrivilegeStatus == true" (click)="navigateCreateGoals(objective._id)" title="Goals" type="button" class="btn btn-outline-info ml-1">
                                        <i class="fas fa-pencil-alt"> </i>  </button>
                                        <button *ngIf="managerEditPrivilegeStatus == true" (click)="clickDelete(objective._id)" type="button" title="Delete" class="btn btn-outline-info ml-1" data-toggle="modal" data-target="#modal-delete">
                                            <i class="fas fa-trash-alt"> </i>  </button>
                                    </span>
                                    <span *ngIf="userSubject.role == 'admin'">
                                        <button *ngIf="userSubject.role == 'admin'" (click)="navigateCreateGoals(objective._id)" title="Goals" type="button" class="btn btn-outline-info ml-1">
                                            <i class="fas fa-pencil-alt"> </i>  </button>
                                        <button (click)="clickDelete(objective._id)" type="button" title="Delete" class="btn btn-outline-info ml-1" data-toggle="modal" data-target="#modal-delete">
                                            <i class="fas fa-trash-alt"> </i>  </button>
                                    </span>

                                    
                                    
       
                                </div>


                            </td>
                        </tr>

                    </tbody>
                </table>
                
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->

    </section>
</div>



<!-- /.modal-create starts -->

<div class="modal fade" id="modal-create" #closebutton aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Create Objectives</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <form [formGroup]="registerForm">
                <div class="modal-body">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Department</label>
                                    <select class="form-control select2 department" (ngModelChange)="onChangeDept($event)" [ngClass]="{ 'is-invalid': submitted && f.department.errors }" formControlName="department" aria-hidden="true">
                                            <option *ngFor="let dept of departmentArray"  [ngValue]="dept._id">{{dept.title}}</option>
                                        </select>
                                    <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                                        <div *ngIf="f.department.errors.required">Department is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Designation</label>
                                    <select class="form-control select2 designation" [ngClass]="{ 'is-invalid': submitted && f.designation.errors }" formControlName="designation" aria-hidden="true">
                                            <option *ngFor="let desig of designationArray"  [ngValue]="desig._id"> {{desig.title}} </option>
                                        </select>
                                    <div *ngIf="submitted && f.designation.errors" class="invalid-feedback">
                                        <div *ngIf="f.designation.errors.required">Designation is required</div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <!-- /.row -->
                    </div>
                </div>
            </form>
            <!-- <div class="modal-footer  justify-content-between"> -->
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="createObjective(registerForm)">Save changes</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="modal fade" id="modal-delete" #closedelete aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Objectives</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="box-body">
                    <p>Are you sure you want to delete this ? </p>
                </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-danger" (click)="deleteObjective()">Delete</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal fade" id="modal-assign" #closegoals aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Import Goals</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <form [formGroup]="importGoalForm">
                <div class="modal-body">
                    <div class="card-body">
                        <div class="form-group" id="import-goals-wrapper">
                            <label for="assignManager">Import Goals From:</label><br>
                            <select2 formControlName="objective"  *ngIf="completedObjectives.length" [data]="completedObjectives" class="form-select"
                            [placeholder]="'Select an option'"
                            ><option value="">Select an option</option></select2>
                            
                        <div *ngIf="objectiveSubmitted && e.objective.errors " class="invalid-feedback">
                            <div *ngIf="e.objective.errors.required">Objective is required</div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" (click)="importGoals(importGoalForm)">Import Goals</button>
                </div>
            </form>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>