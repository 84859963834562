<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4" style="position: fixed; top: 0; left:0;">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
        <img src="../../../assets/pictures/wac.png" alt="Webandcrafts" class="brand-image" style="opacity: .8">
        <span class="brand-text font-weight-light">WAC</span>
    </a>
    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar user (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img src="../../../assets/adminlte/dist/img/user.jpeg" class="img-circle elevation-2" alt="User Image">
            </div>
            <div class="info">
                <a href="/home" class="d-block">{{userSubject.name ? userSubject.name : "user_name"}}</a>
            </div>
        </div>
        <!-- SidebarSearch Form -->
        <div class="form-inline">
            <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-sidebar">
                        <i class="fas fa-search fa-fw"></i>
                    </button>
                </div>
            </div>
        </div>
        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
                <li class="nav-item">
                    <a href="/home" [ngClass]="{'active': currentURL === '/home' || currentURL === '/'}" class="nav-link">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            Dashboard
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/employees" [ngClass]="{'active': currentURL === '/employees'}" class="nav-link">
                        <i class="nav-icon fas fa-users"></i>
                        <p>
                            Employees
                        </p>
                    </a>
                </li>
                <li class="nav-item has-treeview menu-open">
                    <a href="#" [ngClass]="{'active': currentURL === '/indicators-list'}" class="nav-link">
                        <i class="nav-icon fas fa-bullseye"></i>
                        <p>
                            Objectives
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style="display: block;">
                        <li class="nav-item">
                            <a href="/indicators-list" [ngClass]="{'active': currentURL === '/indicators-list'}" class="nav-link">
                                <i class="nav-icon fas fa-list-ul"></i>
                                <p>
                                    Objectives List
                                </p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview menu-open">
                    <a href="#" [ngClass]="{'active': currentURL === '/review-period'  || currentURL === '/review-period/view-review-period'}" class="nav-link">
                        <i class="nav-icon fas fa-toolbox"></i>
                        <p>
                            Review Settings
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style="display: block;">
                        <li class="nav-item">
                            <a href="/review-period" [ngClass]="{'active': currentURL === '/review-period' || currentURL === '/review-period/view-review-period' }" class="nav-link">
                                <i class="nav-icon fas fa-calendar"></i>
                                <p>
                                    Review Periods
                                </p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" *ngIf=" userSubject.isReviewer ">
                    <a href="/members" [ngClass]="{'active': currentURL === '/members'}" class="nav-link">
                        <i class="nav-icon fas fa-user-check"></i>
                        <p>
                            Members 
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/goals" [ngClass]="{'active': currentURL === '/goals'}" class="nav-link">
                        <!-- <i class="nav-icon fas fa-pen"></i> -->
                        <i class="nav-icon fas fa-chess-pawn"></i>
                        <!-- <i class="nav-icon far fa-bullseye-pointer"></i> -->
                        <p>
                            Goals
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/initiated-review" [ngClass]="{'active': currentURL === '/initiated-review'}" class="nav-link">
                        <i class="nav-icon fas fa-comments"></i>
                        <p>
                            Review
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/reports" [ngClass]="{'active': currentURL === '/reports'}" class="nav-link">
                        <i class="nav-icon fas fa-folder"></i>
                        <p>
                            Reports
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/all-reports" [ngClass]="{'active': currentURL === '/all-reports'}" class="nav-link">
                        <i class="nav-icon fas fa-folder"></i>
                        <p>
                            All Users Reports
                        </p>
                    </a>
                </li>

                <li class="nav-item has-treeview menu-open">
                    <a href="#" class="nav-link" [ngClass]="{'active': currentURL === '/settings/designation-list' || currentURL === '/settings/department-list' }">
                        <i class="nav-icon fas fa-cog"></i>
                        <p>
                            Settings
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style="display: block;">
                        <li class="nav-item">
                            <a href="/settings/designation-list" [ngClass]="{'active': currentURL === '/settings/designation-list'}" class="nav-link">
                                <i class="nav-icon fas fa-list-ul"></i>
                                <p>
                                    Designations List
                                </p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/settings/department-list" [ngClass]="{'active': currentURL === '/settings/department-list'}" class="nav-link">
                                <i class="nav-icon fas fa-list-ul"></i>
                                <p>
                                    Department List
                                </p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class=" nav-link">
                                <div class="form-check">
                                        <input class="form-check-input" *ngIf="managerEditPrivilegeStatus == true" checked type="checkbox" value="manager" (change)="editPrivilegeCheck($event)" id="flexCheckChecked" form>
                                        <input class="form-check-input" *ngIf="managerEditPrivilegeStatus == false"  type="checkbox" value="manager" (change)="editPrivilegeCheck($event)" id="flexCheckChecked" form>
                                        <label class="form-check-label " for="flexCheckChecked">
                                            Edit Privilage Status for manager
                                        </label>
                                </div>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>