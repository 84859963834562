import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from "../../services/auth/authentication.service";
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user/user.service';
import { DesignationService } from 'src/app/services/designation/designation.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  registerForm: FormGroup;
  register: any[]
  submitted= false;
  user = {}
  departmentData: any;
  designationData: any;
  roleArray = [ { key: "Admin", value: "admin"},
                { key: "Manager", value: "manager"}, { key: "Staff", value: "staff"} ]
  designationArray: any;

  constructor(
    private formBuilder: FormBuilder,
    private userSerivces: UserService,
    private router : Router,
    private toastr: ToastrService,
    private authenticationService : AuthenticationService,
    private designationServices: DesignationService
    // private localStorageService 
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      employeeId: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      department: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    });
    this.getDepartments();
  }
  get f() {
    return this.registerForm.controls;
  }
  getDepartments = () => {
    this.userSerivces.listDepartment().subscribe((data) => {
      let myData = JSON.parse(JSON.stringify(data));
      if (myData) {
        this.departmentData = myData
      }
    });
  }

  onChangeDept = (deptId)=> {
    this.designationServices.departmentDesignations(deptId).subscribe((data)=>{
      this.designationArray = data;
    });
  }

  signUp(registerForm){
    this.submitted = true;
    if(this.registerForm.invalid) {
      return;
    } else {
      let userData = registerForm.value;
      this.authenticationService.registerUser(userData)
        .subscribe((result)=> {
          let respDetails = JSON.parse(JSON.stringify(result));
          if(respDetails.message == "Success") {
            this.toastr.success(respDetails.message, `Successfully registered user ${respDetails.data.name}`);
            this.router.navigate(['login']);
          }
        },
          (err) => {
            this.toastr.warning(err, 'Error');
            // alert(err.error.message)
          }
        )
    }
  }
  loginPrss(){
    this.router.navigate(['login'])
  }

}
