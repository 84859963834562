import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth/authentication.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private authService: AuthenticationService ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let loggedInUser = this.authService.userValue;
    let token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    return next.handle(request);
  }
}
