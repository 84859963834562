

<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1><b> Review Period {{ pageTitleContent }} Entries</b></h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <a href="/home">Home</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="/review-period">Review Periods</a>
                        </li>
                        <li class="breadcrumb-item active">All Employees Report</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>

    <!-- /.container-fluid -->
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header row">
                            <!-- <h3 class="card-title">All Employee Report</h3> -->
                           
            
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4">
                                <div class="row mb-2">
                                    <div class="col-sm-12 col-md-12 text-right">
                                        <div class="dt-buttons btn-group flex-wrap">
                                            <button class="btn btn-info buttons-copy buttons-html5 mb-2" tabindex="0" aria-controls="example1" type="button" data-toggle="modal" data-target="#modal-assign"><span>Assign Manager</span></button>
                                            <!-- <button class="btn btn-info buttons-copy buttons-html5" tabindex="0" aria-controls="example1" type="button" data-toggle="modal" data-target="#modal-department"><span>Assign Department</span></button> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="tb-employee-list" class="table table-bordered table-hover dataTable dtr-inline" role="grid" aria-describedby="example2_info">
                                            <thead>
                                                <tr role="row">
                                                    <th>
                                                        <input type="checkbox" value="allSelectValue" name="ch-employee" (click)="selectListedAll($event)" id="ch-employee" class="ch-employee" />
                                                    </th>
                                                    <th>
                                                        Employee ID</th>
                                                    <th>
                                                        Name</th>
                                                    <th>
                                                        Designation
                                                    </th>
                                                    <th>
                                                        Department
                                                    </th>
                                                    <th>
                                                        Reviewed By
                                                    </th>
                                                    
                                                    <th>
                                                        Self Review Score
                                                    </th>
                                                    <th>
                                                        Manager Review Score
                                                    </th>
                                                    <th>
                                                        Actions
                                                    </th>
                                                </tr>
              
                                            </thead>
                                            <tbody>
                                                <tr class="odd" *ngFor="let report of allReport">
                                                    <td><input type="checkbox" name="ch-employee" value="{{report._id}}" (change)="checkBoxClick($event, report._id)" class="ch-employee" /></td>
                                                    <td> {{ report.reviewee.employeeId }} </td>
                                                    <td >{{report.reviewee.name}}</td>
                                                    <td *ngIf="report.reviewee.department !== undefined">{{ report.reviewee.revieweeDepartment}}</td>
                                                    <td *ngIf="report.reviewee.designation !== undefined">{{ report.reviewee.revieweeDesgination  }}</td>
                                                    <td >{{ report.reviewer.name}}</td>
                                                    <td >{{ report.total_reviewee_score}}</td>
                                                    <td >{{ report.total_reviewer_score}}</td>

                                                    <td>
                                                        <div class="btn-group">
                                                            <button data-toggle="modal" (click) = "initializeEntryData({'detail' : report.reviewee.name+'('+report.reviewee.employeeId+')','review_id' : report._id})" data-target="#modal-delete" *ngIf="report.completedReviews != 0" type="button"  class="btn btn-danger btn-assign-manager" title="Delete">
                                                                <i class="fas fa-trash"></i> Delete
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <!-- <app-user-reports [selectedDate]></app-user-reports> -->
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->


                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </section>
</div>
<div class="modal fade" id="modal-delete" #closebutton aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Review</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to delete this entry?<br>
                <b style="color: red;">{{ entryDetails }}</b>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="deleteReviewEntry()">Delete</button>
            </div>
        </div>
    </div>
</div>

<!-- /.modal-assign starts -->
<div class="modal fade" id="modal-assign" #closebuttonForAssignManager aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Assign Manager / Reviewer</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <form [formGroup]="registerForm">
                <div class="modal-body">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="assignManager">Assign Manager</label>
                            <select2 formControlName="userReviewer"  *ngIf="reviewerData.length" [data]="reviewerFormattedData" class="form-select"
                            [placeholder]="'Select an option'"
                            ><option value="">Select an option</option></select2>
                            <!-- <select class="form-control" formControlName="userReviewer">
                            <option value="" selected>Select User</option>
                            <option  *ngFor="let user of reviewerData" [ngValue]="user._id" >{{user.name}} {{user.employeeId.length < 6 ? '' : "("+user.employeeId+")"}}</option>
                        </select> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" (click)="changeManager(registerForm)">Save changes</button>
                </div>
            </form>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal-assign ends -->