import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ObjectiveService } from 'src/app/services/objective/objective.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { PrivilegeService } from 'src/app/services/privilege/privilege.service';

@Component({
  selector: 'app-create-indicator',
  templateUrl: './create-indicator.component.html',
  styleUrls: ['./create-indicator.component.css']
})
export class CreateIndicatorComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('closedelete') closedelete;
  @ViewChild('closeObjUpdate') closeObjUpdate;
  @ViewChild('closeGoalUpdate') closeGoalUpdate;

  constructor(
    private route: ActivatedRoute,
    private objectiveService: ObjectiveService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private privilegeService: PrivilegeService,
    private router: Router,
  ) { 
    this.userSubject = JSON.parse(localStorage.getItem('loggedInUser') );
    this.getEditPrivilegeStatus();
   }
  registerForm : FormGroup;
  addGoalForm : FormGroup;
  updateObjForm : FormGroup;
  updateGoalForm : FormGroup;
  objectiveDetails: any;
  objTitleArray: any;
  submitted: Boolean;
  currentObjective: any;
  committed: Boolean
  deleteObjectiveId: any;
  updateObjective: any;
  deleteGoalId: any;
  deleteGoalObjId: any;
  updateGoalData: any;
  updateSubmitted: Boolean;
  updtGoalObjListId: any;
  designationTitle: any;
  departmentTitle: any;
  optionCheck: any;
  userSubject: any;
  managerEditPrivilegeStatus: boolean = true;

  ngOnInit(): void {
    this.route.params.subscribe((params)=>{
      this.currentObjective = params.objectiveId
    });
    if(this.currentObjective) {
      this.loadObjectiveData(this.currentObjective);
    }
    this.submitted = false;

    this.registerForm = this.formBuilder.group({
      objectiveTitle: ['', Validators.required]
    });
    this.addGoalForm = this.formBuilder.group({
      objectivesId: ['', Validators.required],
      goalTitle: ['', Validators.required],
      weightage: ['', Validators.required]
    });
    this.committed = false;
    this.updateObjForm = this.formBuilder.group({
      title: ['', Validators.required]
    });
    this.updateSubmitted = false;
    this.updateGoalForm = this.formBuilder.group({
      goaltitle: ['', Validators.required],
      goalScore: ['', Validators.required]
    });
    
  }
  get f() { return this.registerForm.controls; }
  get e() { return this.addGoalForm.controls; }
  get d() { return this.updateObjForm.controls; }
  get c() { return this.updateGoalForm.controls; }

  getEditPrivilegeStatus = () => {
    if(this.userSubject.role == 'manager') {
      this.privilegeService.getStaffEditStatus().subscribe((data)=> {
        var editStatus:any = data;      
        this.managerEditPrivilegeStatus = editStatus.data;        
        
      });
    }
  }

  loadObjectiveData = (objId)=> {
    setTimeout(() => {
      if(this.managerEditPrivilegeStatus) {
        this.objectiveService.getObjective(objId).subscribe((doc)=> {
          let objDetails = JSON.parse(JSON.stringify(doc));
    
          let objData = objDetails.data.objectives;
          this.optionCheck = objData.length == 0 ? false : objDetails.data.objectives[0]._id;
          
          this.addGoalForm = this.formBuilder.group({
            objectivesId: [this.optionCheck, Validators.required],
            goalTitle: ['', Validators.required],
            weightage: ['', Validators.required]
          });
          if (objDetails.message === "Success") {
            this.objectiveDetails = objDetails.data;
            this.designationTitle = 'NA';
            if(this.objectiveDetails.designation !== null) {
              this.designationTitle = this.objectiveDetails.designation.title;
            }
            this.departmentTitle = this.objectiveDetails.department.title;
            // this.currentObjective = this.objectiveDetails._id;
            this.objTitleArray = this.objectiveDetails.objectives.length ? this.objectiveDetails.objectives : [];
            if (this.objTitleArray.length) {
              this.objTitleArray.forEach((ele)=>{
                let total = 0;
                if(ele.goals && ele.goals.length){
                  ele.goals.forEach((goal)=>{
                    total = total + JSON.parse(goal.score);
                  })
                }
                ele.totalScore = total;
              })
            }
          }
        })
      } else {
          this.router.navigate([`/user/404`]);
      }
    }, 1000);
   
    
  }
  saveObjectiveTitle = (formData) => {
    this.committed = true;
    if(this.registerForm.invalid){
      return
    } else if(formData.value.objectiveTitle && this.currentObjective) {
      let updataData = {
        title: formData.value.objectiveTitle,
        objectiveID: this.currentObjective
      }
      this.objectiveService.addObjectiveTitle(updataData).subscribe((data)=> {
        if( data && (JSON.parse(JSON.stringify(data)).message == "Success")){
          this.ngOnInit()
        }
      },(err)=>{
        this.toastr.warning( err, 'Warning');
      })
    }
  }
  saveGoals = (formData)=> {
    this.submitted = true;
    if(this.addGoalForm.invalid){
      return
    } else {
      let submitData = {
        objectiveID:this.currentObjective,
        objListID: formData.value.objectivesId,
        title: formData.value.goalTitle,
        score: formData.value.weightage
      }
      this.objectiveService.addGoals(submitData).subscribe((doc)=> {
        if( doc && (JSON.parse(JSON.stringify(doc)).message == "Success")){
          this.ngOnInit()
        }
      },(err)=> {
        this.toastr.warning( err, 'Warning');
      })
    }
  }
  selectDltObjective = (d)=> {
    this.deleteObjectiveId = d;
  }
  deleteObjective = ()=> {
    let objData = {
      objectiveID: this.currentObjective ,
      objListID: this.deleteObjectiveId
    }
    this.closedelete.nativeElement.click();
    this.objectiveService.deleteObjective(objData).subscribe((doc)=>{
      if( doc && (JSON.parse(JSON.stringify(doc)).message == "Success")){
        this.ngOnInit()
      }
    })
  }
  selectUpdtObjective = (obj)=> {
    this.updateObjective = obj;
    this.updateObjForm = this.formBuilder.group({
      title: [this.updateObjective.title, Validators.required]
    })
  }
  objectiveUpdate=(formData)=>{
    this.updateSubmitted = true;
    if(this.updateObjForm.invalid){
      return
    } else {
      let data = {
        objectiveID: this.currentObjective,
        objListID: this.updateObjective._id ,
        title: formData.title
      }
      this.closeObjUpdate.nativeElement.click();
      this.objectiveService.updateObjective(data).subscribe((data)=> {
        if(data && (JSON.parse(JSON.stringify(data)).message == "Success") ) {
          this.ngOnInit();
        }
      })
    }

  }
  clickDeleteGoal = (goalId, objId)=> {
    this.deleteGoalId = goalId;
    this.deleteGoalObjId = objId;
  }
  deleteGoal= ()=> {
    if (this.deleteGoalId) {
      let data = { 
        objectiveID: this.currentObjective ,
        objListID: this.deleteGoalObjId,
        goalID: this.deleteGoalId
      }
      this.objectiveService.deleteGoal(data).subscribe((doc)=>{
        this.closebutton.nativeElement.click();
        if( doc && (JSON.parse(JSON.stringify(doc)).message == "Success")){
          this.ngOnInit();
        }
      });
    }
  }
  clickUpdateGoal= (goal, listObjId)=> {
    this.updateGoalData = goal;
    this.updtGoalObjListId = listObjId;
    this.updateGoalForm = this.formBuilder.group({
      goaltitle: [ this.updateGoalData.title, Validators.required],
      goalScore: [ this.updateGoalData.score, Validators.required]
    });
  }
  saveUpdatedGoal = (formData)=> {
    this.updateSubmitted = true;
    if (this.updateGoalForm.invalid) {
      return;
    } else {
      let data = {
        title: formData.value.goaltitle,
        score: formData.value.goalScore,
        objectiveID: this.currentObjective,
        objListID: this.updtGoalObjListId,
        goalID: this.updateGoalData._id
      }
      this.closeGoalUpdate.nativeElement.click();
      this.objectiveService.updateGoal(data).subscribe((doc)=> {
        if (doc && (JSON.parse(JSON.stringify(doc)).message == "Success") ){
          this.ngOnInit();
        }
      }, (err)=> {
        this.toastr.warning( err, 'Warning');
      })
    }

  }

}
