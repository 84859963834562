import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReviewService } from 'src/app/services/review/review.service';
import { DepartmentService } from 'src/app/services/department/department.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ObjectiveService } from 'src/app/services/objective/objective.service';
import { UserService } from 'src/app/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DesignationService } from 'src/app/services/designation/designation.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-view-review-period',
  templateUrl: './view-review-period.component.html',
  styleUrls: ['./view-review-period.component.css']
})
export class ViewReviewPeriodComponent implements OnInit, OnDestroy {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closeEdit') closeEdit;
  @ViewChild('closereviewbutton') closereviewbutton;
  @ViewChild('initiatereviewbutton') initiatereviewbutton;
  @ViewChild('closeassignmanager') closeassignmanager;
  @ViewChild('reopenreviewbutton') reopenreviewbutton;
  @ViewChild('closebuttonForAssignManager') closebuttonForAssignManager;


  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // dtElement2: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10,
    destroy: true,
    ordering: true
  }

  dtTrigger: Subject<any> = new Subject<any>();

  selected: any;
  submitted: Boolean;
  review_Error : Boolean;
  allReviewPeriods: any[] = [];
  allDepartments: any;
  allDesignations = [];
  dropdownSettings:IDropdownSettings;
  selectedDepartments: any[] = [];
  selectedDesignations: any[] = [];
  departmentIds: any[] =[];
  designationIds: any[] =[];
  selfReviewError: Boolean;
  managerReviewError: Boolean;
  editSelectReview: any;
  selectedPeriodUsers: any[];
  nonReviewedUsers: any[];
  selectedPeriodReviewedUsers: any[];
  allUsersModal = document.getElementById('modal-users');
  reviewCompletedUsersModal = document.getElementById('modal-completed-users');
  closeReviewId: any;
  initiateReviewPeriod: any;
  selectedUserReviews: any[] = [];
  reviewersData: any[] = [];
  selectedReviewPeriodId: any;
  adminUserHere: boolean;
  allReport: any = [];
  currentObjective: any;
  requestData: any= {};
  designationData;
  entryDetails : any;
  entryId : any;
  pageTitleContent : any ;
  responseData: any;
  isLoaded: Boolean;
  searchText:any;
  assignMultipleManager: Boolean = false;
  allSelectValue: Boolean;
  selectedUsers: any[] = [];
  changeManagerUsers: any[];
  reviewerData: any[] = [];
  reviewerFormattedData: any[] = [];
  clicked: boolean;
  userReviewer:any;
  isReviewClosed:boolean = false;
  // closebuttonForAssignManager: any;

  
  constructor(
    private formBuilder: FormBuilder,
    private reviewService: ReviewService,
    private departmentService : DepartmentService,
    private toastr: ToastrService,
    private objectiveService: ObjectiveService,
    private userSerivces: UserService,
    private route: ActivatedRoute,
    private designationServices: DesignationService,
    public datepipe: DatePipe
  ) { 
    this.adminUserHere = ( JSON.parse(localStorage.getItem('loggedInUser') ).role == "admin" ) ? true : false;
  }
  registerForm: FormGroup;
  registerForm2: FormGroup;
  managerAssignForm: FormGroup;
  departmentList : any;


  async ngOnInit() {

    this.route.params.subscribe((params)=>{
      this.currentObjective = params.objectiveId
    });

    this.registerForm = this.formBuilder.group({
      userReviewer: ['', [Validators.required]],
      // password: ['']
    })


    await this.getAllDepartments();
    await this.loadDesignationDetails();
    this.loadAllUsersReportsByPeriod(this.currentObjective);
    this.isLoaded = false;

    this.getAllReviewer();

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    // this.dtTrigger2.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      // this.dtTrigger.next();
    });
  };


  reload() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(this.searchText).draw(true);
    });
  }

  getSearchText() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
     this.searchText = dtInstance.search();
    });
  }
  
  loadAllUsersReportsByPeriod = (periodId)=> {
    this.requestData.periodId = periodId;
    this.userSerivces.loadAllUsersReportByPeriod(this.requestData)
        .subscribe((res)=> {
          let usersReport = JSON.parse(JSON.stringify(res));
          if ( usersReport.message == "Success" ){
            this.allReport = usersReport.data.reviewData;
            this.pageTitleContent = '[ ' + usersReport.data.reviewPeriodData.title + ' ( From ' + this.datepipe.transform(usersReport.data.reviewPeriodData.start_date, 'MMM-d-y') + ' To ' + this.datepipe.transform(usersReport.data.reviewPeriodData.end_date, 'MMM-d-y') + ') ]';
            if(this.allReport.length > 0) {
              this.allReport.forEach(element => {
                let dep = this.departmentList.filter(x => x._id === element.reviewee.department);
                let desg = this.designationData.filter(x => x._id === element.reviewee.designation);
                element.reviewee.revieweeDepartment = dep[0].title;
                element.reviewee.revieweeDesgination = desg[0].title;
              });
            }
            
            this.dtTrigger.next();
            // this.reload();
          
          }
        })
  };


  getAllDepartments = ()=> {
    this.departmentService.getAllDepartments().subscribe((data)=> {
      this.departmentList = data;
    })
  }

  loadDesignationDetails = ()=> {
    this.designationServices.getAllDesignations().subscribe((data)=>{
      if (data) {
        this.designationData = data;
      }
    });
  };

  initializeEntryData = (employee) => {
    this.entryDetails = employee.detail;
    this.entryId = employee.review_id;
  }

  deleteReviewEntry = () => {
    this.getSearchText();
    this.requestData.entryId = this.entryId;
    this.reviewService.deleteReview(this.requestData).subscribe((data)=>{
      this.responseData = JSON.parse(JSON.stringify(data))
      if(this.responseData.status) {
        this.rerender();
        this.loadAllUsersReportsByPeriod(this.currentObjective);
        this.closebutton.nativeElement.click();
        this.toastr.success(this.responseData.Message, 'Deleted successfuly');
      } else {
        this.toastr.warning(this.responseData.error, 'Error');
      }
      
    });
  }

    /**
   * Checkbox click event
   * @param event 
   * @param columnValue 
   */
     checkBoxClick = (event, columnValue) => {
      this.assignMultipleManager = true;
      if (event.target.checked === true) {
        this.selectedUsers.push(columnValue);
      } else if (event.target.checked === false) {
        if (this.selectedUsers.includes(columnValue)) {
          this.selectedUsers = this.selectedUsers.filter(ele => ele != columnValue);
        }
      }
    };

      /**
   * Select all users from datatable
   * @param event 
   */
  selectListedAll = (event) => {
    let myCheckbox = document.getElementsByClassName("ch-employee")
    var l = myCheckbox.length;
    if (event.target.checked) {
      this.assignMultipleManager = true;

      if (myCheckbox && myCheckbox.length) {
        for (let i = 1; i < myCheckbox.length; i++) {
          let checkbox = myCheckbox[i] as HTMLInputElement;
          checkbox.checked = true
          this.selectedUsers.push(checkbox.value);
        }
      }
    } else {
      this.assignMultipleManager = false;
      this.changeManagerUsers = [];
      for (let i = 1; i < myCheckbox.length; i++) {
        let checkbox = myCheckbox[i] as HTMLInputElement;
        checkbox.checked = false
      }
      this.selectedUsers = [];
    }
  };


  getAllReviewer = () => {
    this.userSerivces.listReviewer().subscribe((data) => {
      let myData = JSON.parse(JSON.stringify(data));
      if (myData && myData.message == "Success") {
        this.reviewerData = myData.data
        
        this.filterReviewSelectResult()      
      }
    });
  }

  filterReviewSelectResult(){
    this.reviewerData.map((item: any, index:any) => {
        this.reviewerFormattedData.push({
          value: item._id,
          label: item.employeeId + " -  " + item.name,
          data: {
            "color": "red",
            "name": item.employeeId + " " + item.name
          },
          id:item._id,

        });
    
  });
  }

  changeManager = (data) => {
    if (this.registerForm.invalid) {
      this.clicked = false;
      return;
    }else if (data.value.userReviewer) {
      this.clicked = true;
      let reviewData: any;
      reviewData = {
        manager: data.value.userReviewer,
        selectedUsers: this.selectedUsers
      };
      this.reviewService.updateManager(reviewData).subscribe((data) => {
        if (data && JSON.parse(JSON.stringify(data)).message === "Success") {
          this.clicked = false;
          this.selectedUsers = [];
          let checkbox = document.getElementById('ch-employee') as HTMLInputElement
          checkbox.checked = false;
          // this.employeeDetails();
          this.loadAllUsersReportsByPeriod(this.currentObjective);
          this.rerender();
          this.toastr.success(JSON.parse(JSON.stringify(data)).message, `Successfully assigned`);
          this.closebuttonForAssignManager.nativeElement.click();
        }
      })
    } else {
      this.closebuttonForAssignManager.nativeElement.click();
      this.toastr.error(`Something went wrong.`);
    }
  }


}
