import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { Select2Module } from "ng-select2-component";
import { AppLayoutComponent } from './_layouts/app-layout/app-layout.component';
import { MemberLayoutComponent } from './_layouts/member-layout/member-layout.component';
// import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { LoginComponent } from './pages/login/login.component';
import { IndicatorsComponent } from './pages/indicators/indicators.component';
import { ListIndicatorsComponent } from './pages/list-indicators/list-indicators.component';
import { ReviewPeriodComponent } from './pages/review-period/review-period.component';
import { MemberComponent } from './pages/member/member.component';
import { CreateIndicatorComponent } from './pages/create-indicator/create-indicator.component';
import { SelfReviewComponent } from './pages/self-review/self-review.component';
import { ManagerReviewComponent } from './pages/manager-review/manager-review.component';
import { FinalReviewComponent } from './pages/final-review/final-review.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/role';
import { InitiatedReviewComponent } from './pages/initiated-review/initiated-review.component';
import { RegistrationComponent } from './pages/registration/registration.component'
import { NotFoundComponent } from './pages/404/NotFoundComponent.component';
// import { ReportsModule } from './pages/reports/reports.module';


const routes: Routes = [
//   {
//   path: '',
//   component: RouterDirectiveComponent
// },
{
  path:"login",
  component: LoginComponent
},
{
  path:"register",
  component: RegistrationComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Admin] },
},
// Admin routes goes here 
{
  path: '',
  component: AppLayoutComponent, 
  canActivate: [AuthGuard],
  // data: { roles: [Role.Admin] },
  children: [
    { path: '', loadChildren: ()=> import('./pages/dashboard/dashboard.module').then(m=> m.DashboardModule) },
    { path: 'employees', loadChildren: ()=> import('./pages/employee-list/employee-list.module').then(m => m.EmployeeListModule) },
    { path: 'goals', component: IndicatorsComponent },
    { path: 'indicators-list/:objectiveId', component: IndicatorsComponent },
    { path: 'indicators-list', component: ListIndicatorsComponent },
    { path: 'review-period', loadChildren: ()=> import('./pages/review-period/review-period.module').then(m=> m.ReviewPeriodModule) },
    { path: 'review-period/view-review-period/:objectiveId', loadChildren: ()=> import('./pages/review-period/view-review-period/view-review-period.module').then(m=> m.ViewReviewPeriodModule) },
    { path: 'create-goals/:objectiveId', component: CreateIndicatorComponent },
    { path: 'self-review/:reviewId', component: SelfReviewComponent },
    { path: 'members', component: MemberComponent },
    { path: 'manager-review/:reviewId', component: ManagerReviewComponent },
    { path: 'final-review/:reviewId', component: FinalReviewComponent },
    { path: 'initiated-review', component: InitiatedReviewComponent },
    { path: 'reports', loadChildren: ()=> import('./pages/reports/reports.module').then(m => m.ReportsModule) },
    { path: 'all-reports', loadChildren: ()=> import('./pages/all-user-reports/all-user-reports.module').then(m=> m.AllUserReportsModule) },
    { path: 'settings', loadChildren: ()=> import('./pages/settings/settings.module').then(m=> m.SettingsModule) },

  ]
},
// staff routes goes here 
{ 
  path: 'user',
  component: MemberLayoutComponent, 
  canActivate: [AuthGuard],
  data: { roles: [Role.Staff, Role.Manager] },
  children: [
    { path: '',  loadChildren: ()=> import('./pages/dashboard/dashboard.module').then(m=> m.DashboardModule) },
    { path: 'indicators', component: IndicatorsComponent },
    { path: 'members', component: MemberComponent },
    { path: 'self-review/:reviewId', component: SelfReviewComponent },
    { path: 'initiated-review', component: InitiatedReviewComponent },
    { path: 'manager-review/:reviewId', component: ManagerReviewComponent },
    { path: 'final-review/:reviewId', component: FinalReviewComponent },
    { path: 'reports', loadChildren: ()=> import('./pages/reports/reports.module').then(m => m.ReportsModule) },
    // { path: 'all-reports', loadChildren: ()=> import('./pages/all-user-reports/all-user-reports.module').then(m=> m.AllUserReportsModule) }

  ]
},
{ 
  path: 'user',
  component: MemberLayoutComponent, 
  canActivate: [AuthGuard],
  data: { roles: [Role.Manager] },
  children: [
    { path: 'indicators-list', component: ListIndicatorsComponent },
    { path: 'indicators-list/:objectiveId', component: IndicatorsComponent },
    { path: 'create-goals/:objectiveId', component: CreateIndicatorComponent },
    { path: '404', component: NotFoundComponent },

  ]
},
{ path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
