import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { Select2Module } from "ng-select2-component";

import { ViewReviewPeriodComponent } from './view-review-period.component';


const routes: Routes = [
  {
    path: '',
    component: ViewReviewPeriodComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewReviewPeriodRoutingModule { }
