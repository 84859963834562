<div class="content-wrapper">

    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1> Final Review </h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Objectives & Goals</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>


    <section class="content">
        <div class="row">
            <div class="col-lg-12 card">
                <div class="row">
                    <div class="col-8 mt-5" id="accordion">
                        <div class="card card-primary card-outline" *ngFor="let obj of objTitleArray; let i=index">
                            <a class="d-block w-100" data-toggle="collapse" href="#collapseOne" aria-expanded="true">
                                <div class="card-header">

                                    <div class="row">
                                        <div class="col-8">
                                            <span class="objective">
                                    {{i + 1 }}. {{obj.title}}

                                    </span>
                                        </div>

                                        <div class="col-4 text-right">
                                            <span class="badge badge-success right">
                                        {{obj.totalScore}}
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    <table class="table ">
                                        <tbody>
                                            <ng-container *ngFor="let goal of obj.goals">
                                                <tr class="tr-header">
                                                    <td colspan=2 class="mailbox-subject"> <span><i class="fas fa-star text-warning"></i></span> {{goal.title}}
                                                    </td>
                                                    <td class="mailbox-date text-right"><span class="badge badge-info right">{{goal.score}}</span></td>
                                                </tr>
                                                <tr>



                                                </tr>
                                                <tr class="odd">
                                                    <td colspan="3">

                                                        <div class="direct-chat-msg " *ngIf="reviewDetails.is_reviewee_completed">
                                                            <div class="direct-chat-infos clearfix">
                                                                <span class="direct-chat-name float-left">{{ reviewDetails.reviewee.name }}</span>
                                                            </div>
                                                            <!-- /.direct-chat-infos -->
                                                            <img class="direct-chat-img" src="../../../assets/adminlte/dist/img/user.jpeg" alt="Message User Image">
                                                            <!-- /.direct-chat-img -->
                                                            <div class="direct-chat-text">
                                                                <div class="row">
                                                                    <div class="col-8">
                                                                        <star-rating value="{{goal.reviewee_rating}}" [totalstars]="5" checkedcolor="gold" uncheckedcolor="grey" size="16px" readonly="true"></star-rating>
                                                                    </div>
                                                                    <div class="col-4 text-right">
                                                                        <span class="badge badge-secondary right">
                                                                            {{goal.reviewee_rating}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {{goal.reviewee_comment}}
                                                            </div>
                                                            <!-- /.direct-chat-text -->
                                                        </div>

                                                        <div class="direct-chat-msg" *ngIf="reviewDetails.is_reviewer_completed">
                                                            <div class="direct-chat-infos clearfix">
                                                                <span class="direct-chat-name float-left">{{ reviewDetails.reviewer.name }}</span>
                                                            </div>
                                                            <!-- /.direct-chat-infos -->
                                                            <img class="direct-chat-img" src="../../../assets/adminlte/dist/img/manager.png" alt="Message User Image">
                                                            <!-- /.direct-chat-img -->
                                                            <div class="direct-chat-text">
                                                                <div class="row">
                                                                    <div class="col-8">
                                                                        <star-rating value="{{goal.reviewer_rating}}" [totalstars]="5" checkedcolor="gold" 
                                                                                uncheckedcolor="grey" size="16px" readonly="true"></star-rating>
                                                                    </div>
                                                                    <div class=" col-4 text-right">
                                                                        <span class="badge badge-secondary right">
                                                                            {{goal.reviewer_rating}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {{goal.reviewer_comment}}

                                                            </div>
                                                            <!-- /.direct-chat-text -->
                                                        </div>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 mt-5">
                        <div class="card card-widget widget-user-2">
                            <!-- Add the bg color to the header using any of the bg-* classes -->
                            <div class="widget-user-header bg-info">
                                <!-- /.widget-user-image -->
                                <h3 class="widget-user-username" style="margin-left:0%;">{{ reviewTitle }} <span>({{ this.startDate }} - {{this.endDate}})</span></h3>
                                <h5 class="widget-user-desc" style="margin-left:0%;">{{ reviewDesignation}} ({{ reviewDepartment }})</h5>
                            </div>
                            <div class="card-footer p-0">
                                <ul class="nav flex-column">
                                    <li class="nav-item">
                                        <a class="nav-link">Initiated On <span class="float-right badge bg-primary">{{ this.initatedDate }}</span></a>
                                    </li>

                                    <li class="nav-item" *ngIf="isReviewCompleted && managerReviewedDate;else reviewNotComplete">
                                        <a class="nav-link">Status <span class="float-right badge bg-success">Review Completed</span></a>
                                    </li>

                                    <ng-template #reviewNotComplete>
                                        <li class="nav-item">
                                            <a class="nav-link">Status <span class="float-right badge bg-info">Self Review Completed</span></a>
                                        </li>

                                    </ng-template>

                                    <li class="nav-item" *ngIf="isReviewCompleted">
                                        <a class="nav-link">Self Reviewed On <span class="float-right badge bg-info">{{ this.selfRatedDate }}</span></a>
                                        <a class="nav-link">Self Rated Point <span class="float-right badge bg-success">{{ this.totalReviewee_score }}</span></a>
                                    </li>

                                    <li class="nav-item" *ngIf="isManagerReviewCompleted">
                                        <a class="nav-link">Manager Reviewed On <span class="float-right badge bg-info">{{ this.managerReviewedDate }}</span></a>
                                        <a class="nav-link">Manager Rated Point <span class="float-right badge bg-success">{{ this.totalReviewer_score }}</span></a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link">Comments</a>
                                        <div class="col-12 mb-2">
                                            <div class="direct-chat-msg">
                                                <!-- /.direct-chat-infos -->
                                                <img class="direct-chat-img" src="../../../assets/adminlte/dist/img/user.jpeg" alt="Message User Image">
                                                <!-- /.direct-chat-img -->
                                                <div class="direct-chat-text">
                                                    {{ revieweeComment }}
                                                </div>
                                                <!-- /.direct-chat-text -->
                                            </div>

                                        </div>

                                        <div class="col-12 mb-2" *ngIf="isManagerReviewCompleted;">
                                            <div class="direct-chat-msg">
                                                <!-- /.direct-chat-infos -->
                                                <img class="direct-chat-img" src="../../../assets/adminlte/dist/img/manager.png" alt="Message User Image">
                                                <!-- /.direct-chat-img -->
                                                <div class="direct-chat-text">
                                                    {{ reviewerComment }}
                                                </div>
                                                <!-- /.direct-chat-text -->
                                            </div>

                                        </div>

                                    </li>



                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </section>

</div>