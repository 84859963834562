import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  

  constructor(private http: HttpClient) { }

  loginUser(user) {
    let myresponse = this.http.post(`${baseUrl}/login`, user);
    return myresponse;
  }
  getAllUsers() {
    let allUsers = this.http.get(`${baseUrl}/user/all`);
    return allUsers;
  }
  listReviewer() {
    let user = this.http.get(`${baseUrl}/user/reviewer`);
    return user;
  }
  listDepartment() {
    let department = this.http.get(`${baseUrl}/register/department`);
    return department;
  }
  listDesignation() {
    let designation = this.http.get(`${baseUrl}/register/designation`);
    return designation;
  }
  updateManager(upData) {
    let updateData = this.http.put(`${baseUrl}/user/updateManager`, upData);
    return updateData;
  }
  updateDepartment(upData) {
    let updateData = this.http.put(`${baseUrl}/user/updateDepartment`, upData);
    return updateData;
  }
  updateDesignation(upData) {
    let updateData = this.http.put(`${baseUrl}/user/updateDesignation`, upData);
    return updateData;
  }
  deleteUser(delUser) {
    let deleteData = this.http.put(`${baseUrl}/user/delete`, delUser);
    return deleteData;
  }
  updateToReview(user) {
    let userData = { userId: user._id }
    let updateData = this.http.put(`${baseUrl}/user/reviewer`, userData);
    return updateData;
  }
  changePassword(newPassword, user) {
    // let myData = { passWord: newPassword }
    let updateData = this.http.put(`${baseUrl}/register/changePassword`, { _id: user._id, password: newPassword });
    return updateData;
  }
  getStaffMembers(userId) {
    const params = new HttpParams()
      .set('userId', userId) 
    let memberList = this.http.get(`${baseUrl}/staff/member`, { params: params });
    return memberList;
  }
  generatePasswordResetCode(emailId) {
    let updateData = this.http.put(`${baseUrl}/login/generateCode`, { emailId: emailId })
    return updateData;
  }
  resetPassword(resetData) {
    let updateData = this.http.put(`${baseUrl}/login/resetPassword`,resetData );
    return updateData;
  }
  getUsersCount() {
    let updateData = this.http.get(`${baseUrl}/user/usersCount`);
    return updateData;
  }

  generateResetCode() {
    let updateData = this.http.put(`${baseUrl}/staff/generateCode`, { })
    return updateData;
  };

  userUpdatePassword(values) {
    let updateData = this.http.put(`${baseUrl}/staff/resetPassword`, values )
    return updateData;
  };

  loadUsersReport(){
    let reportData = this.http.get(`${baseUrl}/user/usersReport`)
    return reportData;
  };

  loadAllUsersReport(date){
    let reportData = this.http.put(`${baseUrl}/user/allUsersReport`, date)
    return reportData;
  };

  roleBasedFilter(role){
    const param = new HttpParams()
      .set('role', role)
    let userData = this.http.get(`${baseUrl}/user/filteredUsers`, {params: param});
    return userData;
  }

  getBestPerformers() {
    let responseData = this.http.get(`${baseUrl}/user/bestPerformers`);
    return responseData;
  }

  loadAllUsersReportByPeriod(periodId) {
    let reportData = this.http.put(`${baseUrl}/reviewPeriod/userReportByPeriodId`, periodId)
    return reportData;
  }

  updateUser(userInfo) {
    let myresponse = this.http.post(`${baseUrl}/user/updateEmail`, userInfo);
    return myresponse;
}

  editUserData(userId:any){
  return this.http.post(`${baseUrl}/user/details`,{userId : userId});
  }
  updateUserData(dataToUpdate:any) {
    return this.http.put(`${baseUrl}/user/details-update`,dataToUpdate);
  }
}
