import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateIndicatorComponent } from './create-indicator.component'
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
// import { ObjectiveService } from 'src/app/services/objective/objective.service';


@NgModule({
  declarations: [
    CreateIndicatorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 1150,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation:"increasing",
      resetTimeoutOnDuplicate: true
    }),
    BrowserAnimationsModule
  ],
  providers: [
    // ObjectiveService
  ],
})
export class CreateIndicatorModule { }
