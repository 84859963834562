import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StarRatingComponent } from 'ng-starrating';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ObjectiveService } from 'src/app/services/objective/objective.service';
import { ReviewService } from 'src/app/services/review/review.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-self-review',
  templateUrl: './self-review.component.html',
  styleUrls: ['./self-review.component.css']
})
export class SelfReviewComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private objectiveService: ObjectiveService,
    private reviewService: ReviewService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }

  registerForm: FormGroup;
  reviewDetails: any;
  reviewTitle: any;
  reviewDesignation: any;
  reviewDepartment: any;
  objectiveDetails: any;
  objTitleArray: any;
  reviewParamsKey: any;
  datePipe: any;
  startDate: any;
  endDate: any;
  initatedDate: any;
  isReviewCompleted: Boolean;
  selfRatedDate: any;
  managerReviewedDate: any;
  submitted: Boolean = false;
  reviewComment: any;
  selfReviewLastDate: any;

  ngOnInit(): void {
    this.datePipe = new DatePipe('en-US')
    this.route.params.subscribe((params) => {
      this.reviewParamsKey = params.reviewId;
      this.getIndividualReviews(this.reviewParamsKey)
    })
    this.registerForm = this.formBuilder.group({
      reviewee_comment: ['', Validators.required]
    });

  }
  get f() { return this.registerForm.controls; }

  getIndividualReviews = (reviewId) => {
    this.reviewService.getIndividualReviews(reviewId).subscribe((doc) => {
      let reviewDetails = JSON.parse(JSON.stringify(doc));
      if (reviewDetails.message === "Success") {
        this.reviewDetails = reviewDetails.data;
        this.reviewTitle = this.reviewDetails.review_period_id.title;
        this.reviewDepartment = this.reviewDetails.reviewee.department.title;
        this.reviewDesignation = this.reviewDetails.reviewee.designation.title;
        this.startDate = this.datePipe.transform(this.reviewDetails.review_period_id.start_date);
        this.endDate = this.datePipe.transform(this.reviewDetails.review_period_id.end_date);
        this.initatedDate = this.datePipe.transform(this.reviewDetails.review_period_id.initiate_date);
        this.selfRatedDate = this.datePipe.transform(this.reviewDetails.reviewee_completed_on);
        this.isReviewCompleted = this.reviewDetails.is_reviewee_completed;
        this.selfReviewLastDate = this.datePipe.transform(this.reviewDetails.review_period_id.selfReviewEndDate);
        // this.currentObjective = this.objectiveDetails._id;
        this.objTitleArray = this.reviewDetails.objectives.length ? this.reviewDetails.objectives : [];
        if (this.objTitleArray.length) {
          this.objTitleArray.forEach((ele) => {
            let total = 0;
            if (ele.goals && ele.goals.length) {
              ele.goals.forEach((goal) => {
                total = total + JSON.parse(goal.score);
              })
            }
            ele.totalScore = total;
          })
        }
      }
    })
  }
  textAreaUpdate(event, goalId, objId) {
    let updateData = {
      reviewId: this.reviewDetails._id,
      objectiveId: objId,
      goalId: goalId,
      review: event.target.value
    }
    this.reviewService.updateSelfReview(updateData).subscribe((data) => {
      if (data && (JSON.parse(JSON.stringify(data)).message === "Success")) {
        this.toastr.success('Successfully Updated', 'Success');
        this.getIndividualReviews(this.reviewParamsKey)
      }
    });
  }
  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }, goalId, objId) {
    // alert(`Old Value:${$event.oldValue}, 
    //   New Value: ${$event.newValue}, 
    //   Checked Color: ${$event.starRating.checkedcolor}, 
    //   Unchecked Color: ${$event.starRating.uncheckedcolor}, ${goalId}`);
    let updateData = {
      reviewId: this.reviewDetails._id,
      objectiveId: objId,
      goalId: goalId,
      rating: $event.newValue
    }
    // this.reviewService.updateSelfReview(updateData).subscribe((data) => {
    //   if (data && (JSON.parse(JSON.stringify(data)).message)) {
    //     this.getIndividualReviews(this.reviewParamsKey)
    //   }
    // });
    this.reviewService.updateSelfReview(updateData).subscribe((data) => {
      if (data && (JSON.parse(JSON.stringify(data)).message === "Success")) {
        this.toastr.success('Successfully Updated', 'Success');
        this.getIndividualReviews(this.reviewParamsKey)
      }
    });
  }
  clickSubmit(formData){
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      this.reviewComment = formData.value.reviewee_comment;
    }
  }
  submitComment(){
    if (this.reviewComment) {
      let updateData = {
        reviewId: this.reviewParamsKey,
        comment: this.reviewComment
      }
      this.reviewService.closeSelfReview(updateData).subscribe(( data )=> {
        let response = JSON.parse(JSON.stringify(data));
        if (response.message === "Success") {
          this.toastr.success('Successfully Updated', 'Success');
          this.getIndividualReviews(this.reviewParamsKey)
        }
      }, (err)=> this.toastr.warning(err, '') );
    }
  }


}
