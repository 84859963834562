import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListIndicatorsComponent } from './list-indicators.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DesignationService } from '../../services/designation/designation.service';
import { DepartmentService } from 'src/app/services/department/department.service'
// import { NgSelect2Module } from 'ng-select2';
import { Select2Module } from "ng-select2-component";
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    ListIndicatorsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    Select2Module
  ],
  providers: [
    DesignationService,
    DepartmentService
  ],
})
export class ListIndicatorsModule { }
