import { Component, OnInit } from '@angular/core';
import { PrivilegeService } from 'src/app/services/privilege/privilege.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styles: [
  ]
})
export class AppSidebarComponent implements OnInit {
  userSubject: any;
  currentURL: any;
  managerEditPrivilegeStatus: boolean;

  constructor( 
    private privilegeService: PrivilegeService,
    private toastr: ToastrService,
   ) {
    this.userSubject = JSON.parse(localStorage.getItem('loggedInUser') );
    
   }

  ngOnInit(): void {
    this.currentURL = window.location.pathname;
    this.getEditPrivilegeStatus();
  }

  editPrivilegeCheck = (eventData) => {
    this.privilegeService.updateEditPrivilege(eventData.target.value).subscribe((data)=> {
      var editStatus = JSON.parse(JSON.stringify(data));      
      if(editStatus.data) {
        this.managerEditPrivilegeStatus = editStatus.data;
        this.toastr.success(JSON.parse(JSON.stringify(data)).message, `Enabled edit privilege for manager`);
      } else {
        this.managerEditPrivilegeStatus = editStatus.data;
        this.toastr.success(JSON.parse(JSON.stringify(data)).message, `Disabled edit privilege for manager`);
      }

    });
  }

  getEditPrivilegeStatus = () => {
    this.privilegeService.getStaffEditStatus().subscribe((data)=> {
      var editStatus = JSON.parse(JSON.stringify(data));      
      this.managerEditPrivilegeStatus = editStatus.data;
    });
  }

}
