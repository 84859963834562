import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { baseUrl } from 'src/environments/environment';
import { BehaviorSubject,Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private loggedUserSubject: BehaviorSubject<any>;
  userSubject: any;
  // user: Observable<any>;
  public loggedInUser: Observable<any>;


  constructor(
      private router: Router,
      private http: HttpClient
  ) { 
    this.userSubject = JSON.parse(localStorage.getItem('loggedInUser') );
    this.loggedUserSubject = new BehaviorSubject(this.userSubject);
    this.loggedInUser = this.loggedUserSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject;
  }
  loginUser(userData) {
    return this.http.post<any>(`${baseUrl}/login`, userData)
        .pipe(map(response=> {
          let resDetails = JSON.parse(JSON.stringify(response))
            localStorage.setItem('token', JSON.stringify(resDetails.token));
            localStorage.setItem('loggedInUser', JSON.stringify(resDetails.user));
            this.loggedUserSubject.next(response);
            this.userSubject = JSON.parse(localStorage.getItem('loggedInUser') );
            return response;
        }));
  }
  registerUser(userDetails) {
    return this.http.post<any>(`${baseUrl}/register`, userDetails)
      .pipe(map(resp => {
        let resData = JSON.parse(JSON.stringify(resp));
        return resp;
      }))
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
    // this.userSubject.next(null);
  }


}
