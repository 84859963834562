import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject<any>();
  userObject: any;
  memberList: any;

  constructor(
    private userService: UserService,
    private router : Router
  ) { 
    this.userObject = JSON.parse(localStorage.getItem('loggedInUser') );
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.userObject = JSON.parse(localStorage.getItem('loggedInUser') );
    this.callMembersList()
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    // $.fn.dataTable.ext.search.pop();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      // this.dtTrigger.next();
    });
  }

  callMembersList = ()=> {
    this.userService.getStaffMembers(this.userObject._id).subscribe((data)=>{
      if (data && (JSON.parse(JSON.stringify(data)).message == "Success") ) {
        let response = JSON.parse(JSON.stringify(data));
        response.data.forEach((review)=> {
          review.completePercentage = 0;
          if (review.is_reviewee_completed) {
            review.completePercentage = 50;
          } 
          if (review.is_reviewer_completed) {
            review.completePercentage = 100;
          } 
        })
        this.memberList = response.data;

      this.dtTrigger.next();
      }
    })
  };
  managerReview(reviewId){
    if((this.userObject.role == "staff") || (this.userObject.role == "manager") ){
      this.router.navigate([`user/manager-review/${reviewId}`]);
    } else if(this.userObject.role == "admin") {
      this.router.navigate([`manager-review/${reviewId}`])
    }
  }
  finalReview(reviewId){
    if((this.userObject.role == "staff") || (this.userObject.role == "manager") ){
      this.router.navigate([`user/final-review/${reviewId}`]);
    } else if(this.userObject.role == "admin") {
      this.router.navigate([`final-review/${reviewId}`])
    }
  }


}
