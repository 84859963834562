<div class="content-wrapper">

    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Self Review </h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a>Home</a></li>
                        <li class="breadcrumb-item active">Objectives & Goals</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>


    <section class="content">
        <div class="row">
            <div class="col-lg-12 card">

                <div class="row">
                    <div class="col-8 mt-5" id="accordion">
                        <div class="card card-primary card-outline" *ngFor="let obj of objTitleArray; let i=index">
                            <a class="d-block w-100" data-toggle="collapse" href="#collapseOne" aria-expanded="true">
                                <div class="card-header">

                                    <div class="row">
                                        <div class="col-8">
                                            <span class="objective">
                                    {{i + 1 }}. {{obj.title}}

                                    </span>
                                        </div>

                                        <div class="col-4 text-right">
                                            <span class="badge badge-success right">
                                        {{obj.totalScore}}
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    <table class="table ">
                                        <tbody>
                                            <ng-container *ngFor="let goal of obj.goals">
                                                <tr class="tr-header">
                                                    <td colspan=2 class="mailbox-subject"> <span><i class="fas fa-star text-warning"></i></span> {{goal.title}}
                                                    </td>
                                                    <td class="mailbox-date text-right"><span class="badge badge-info right">{{goal.score}}</span></td>
                                                </tr>
                                                <tr>



                                                </tr>
                                                <tr class="even">
                                                    <td colspan="2">
                                                        <div class="row">
                                                            <div class="manager-block col-md-1">
                                                                <img class="img-circle img-bordered-sm" src="../../../assets/adminlte/dist/img/user.jpeg" alt="user image">

                                                            </div>
                                                            <div class="col-md-11">
                                                                <textarea [disabled]="reviewDetails.is_reviewee_completed" class="form-control" placeholder="Describe your actvity of work performed based on the goal" (change)="textAreaUpdate($event,goal._id, obj._id )">{{ goal.reviewee_comment}}</textarea>
                                                            </div>
                                                        </div>


                                                    </td>
                                                    <td colspan="1" class="text-right" style=" width: 15%;">
                                                        <star-rating value="{{ goal.reviewee_rating }}" [totalstars]="5" checkedcolor="gold" uncheckedcolor="grey" size="20px" [readonly]=reviewDetails.is_reviewee_completed (rate)="onRate($event, goal._id, obj._id)"></star-rating>

                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-4 mt-5">
                        <div class="card card-widget widget-user-2">
                            <!-- Add the bg color to the header using any of the bg-* classes -->
                            <div class="widget-user-header bg-info">
                                <!-- /.widget-user-image -->
                                <h3 class="widget-user-username" style="margin-left:0%;">{{ reviewTitle }} <span>({{ this.startDate }} - {{this.endDate}})</span></h3>
                                <h5 class="widget-user-desc" style="margin-left:0%;">{{ reviewDesignation}} ({{ reviewDepartment }})</h5>
                            </div>
                            <div class="card-footer p-0">
                                <ul class="nav flex-column">
                                    <li class="nav-item">
                                        <a class="nav-link">Initiated On <span class="float-right badge bg-primary">{{ this.initatedDate }}</span></a>
                                    </li>
                                    <li class="nav-item" *ngIf="!reviewDetails.is_reviewee_completed">
                                        <a class="nav-link">Self Review Last Date <span class="float-right badge bg-info">{{ this.selfReviewLastDate }}</span></a>
                                    </li>

                                    <li class="nav-item" *ngIf="isReviewCompleted">
                                        <a class="nav-link">Status <span class="float-right badge bg-info">Self Review Completed</span></a>
                                    </li>

                                    <li class="nav-item" *ngIf="isReviewCompleted">
                                        <a class="nav-link">Self Reviewed On <span class="float-right badge bg-info">{{ this.selfRatedDate }}</span></a>
                                    </li>

                                    <li class="nav-item" *ngIf="isReviewCompleted;else getComment">
                                        <a class="nav-link">Comments</a>
                                        <div class="col-12 mb-2">
                                            <div class="direct-chat-msg">
                                                <!-- /.direct-chat-infos -->
                                                <img class="direct-chat-img" src="../../../assets/adminlte/dist/img/user.jpeg" alt="Message User Image">
                                                <!-- /.direct-chat-img -->
                                                <div class="direct-chat-text">
                                                    {{ reviewDetails.reviewee_comment }}
                                                </div>
                                                <!-- /.direct-chat-text -->
                                            </div>
                                        </div>
                                    </li>
                                    <ng-template #getComment>
                                        <form [formGroup]="registerForm"> 
                                            <li class="nav-item">
                                                <a class="nav-link">Comments </a>
                                                <div class="col-12 mb-2">
                                                    <textarea class="form-control nav-link" formControlName="reviewee_comment" [ngClass]="{ 'is-invalid': submitted && f.reviewee_comment.errors }"
                                                             placeholder="Provide an overall experience and suggestions on what need to be improved"></textarea>
                                                    <div *ngIf="submitted && f.reviewee_comment.errors" class="invalid-feedback">
                                                        <div *ngIf="f.reviewee_comment.errors.required">Comment required</div>
                                                    </div>
                                                </div>
    
                                                <div class="col-12 mb-2">
                                                    <input type="button" class="btn btn-info float-right mb-2"  data-toggle="modal" data-target="#modal-comment" (click)="clickSubmit(registerForm)" value="Submit Review" />
                                                </div>
    
                                            </li>
                                        </form>
         
                                    </ng-template>
                                    <div class="modal fade" id="modal-comment" #closebutton aria-hidden="true" style="display: none;">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-body">
                                                    <p>Do you wish to Submit the comment ?</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                                    <button type="button" class="btn btn-primary"  data-dismiss="modal" (click)="submitComment()">Save </button>
                                                </div>
                                            </div>
                                            <!-- /.modal-content -->
                                        </div>
                                        <!-- /.modal-dialog -->
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </section>

</div>