<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Members</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Members</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <section class="content">
    
        <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Members</h3>
    
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="card-body p-4">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped projects">
                    <thead>
                        <tr>
                            <th style="width: 1%">
                                #
                            </th>
                            <th style="width: 20%">
                                Team Member
                            </th>
                            <th>
                                Review Period
                            </th>
                            <th>
                                Review Progress
                            </th>
                            <th class="text-center">
                                Status
                            </th>
                            <th class="text-center" style="width: 20%">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let member of memberList">
                            <td>
                                #
                            </td>
                            <td>
                                <a>
                                    {{ member.employeeId }}
                                </a>
                                <br>
                                <small>
                                    {{ member.user_name }}
                                </small>
                            </td>
                            <td>
                                {{ member.title }}
                                <br>
                                <small>
                                    {{member.start_date | date: "MMM-d-y"}} - {{member.end_date | date: "MMM-d-y"}}
                                </small>
                            </td>
                            <td class="project_progress">
                                <div class="progress progress-sm">
                                    <div class="progress-bar bg-green" role="progressbar" aria-valuenow= "20"
                                        aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': member.completePercentage + '%'}" >
                                    </div>
                                </div>
                                <small>
                                    {{ member.completePercentage }}% Complete
                                </small>
                            </td>
                            <td class="project-state">
                                <span *ngIf="member.completePercentage === 100" class="badge badge-success">Completed</span>
                                <span *ngIf="!(member.completePercentage === 100)" class="badge badge-warning">Pending</span>
                            </td>
                            <td class="project-actions text-right">
                                <button class="btn btn-primary btn-sm" (click)="finalReview(member.reviewId)" *ngIf="member.completePercentage == 100">
                                    <i class="fas fa-folder">
                                    </i>
                                    View
                                </button>
                                <button class="btn btn-info btn-sm" (click)="managerReview(member.reviewId)" *ngIf="member.completePercentage == 50">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                    Review
                                </button>
                               
                            </td>
                        </tr>
                    
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    
    </section>
</div>