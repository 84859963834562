import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfReviewComponent } from './self-review.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RatingModule } from 'ng-starrating';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    SelfReviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RatingModule,
    ToastrModule.forRoot({
      timeOut: 1150,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation:"increasing",
      resetTimeoutOnDuplicate: true
    }),
    BrowserAnimationsModule,
  ],
  bootstrap: [SelfReviewComponent]
})
export class SelfReviewModule { }
