import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Login } from '../../_models/login';
import { UserService } from "../../services/user/user.service";
import { Router } from '@angular/router';
import { AuthenticationService } from "../../services/auth/authentication.service";
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
// import { HttpClient, HttpHeaders } from '@angular/common/http'
// import { LocalStorageService } from '@ang'
// import { LocalStorageService } from './local-storage.service';
// import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  registerForm: FormGroup;
  codeGenerateForm: FormGroup;
  resetPasswordForm: FormGroup;
  register: any[]
  submitted= false;
  generateCodePress: Boolean = false;
  user = new Login(null, null)
  newPasswordField: boolean = false;
  resetPasswordSubmit: Boolean = false

  constructor( 
        private formBuilder: FormBuilder,
        private userSerivces: UserService,
        private router : Router,
        private toastr: ToastrService,
        private authenticationService : AuthenticationService,
        // private localStorageService : LocalStorageService
        // private Headers: Headers
    ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    });
    this.codeGenerateForm = this.formBuilder.group({
      registeredEmail: ['', [Validators.required, Validators.email]],
    })
    this.resetPasswordForm = this.formBuilder.group({
      resetCode: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(4)]]
    })

  }

  get f() {
    return this.registerForm.controls;
  }
  get g() {
    return this.codeGenerateForm.controls;
  }
  get h() {
    return  this.resetPasswordForm.controls;
  }

  login(){
    this.submitted = true;
    if(this.registerForm.invalid) {
      return;
    } else {
      this.user.email = this.registerForm.get("email").value;
      this.user.password = this.registerForm.get("password").value;
      // this.userSerivces.loginUser(this.user)
      // this.authenticationService.loginUser(this.user)
      this.authenticationService.loginUser(this.user)
        .subscribe((result)=> {
          let respDetails = JSON.parse(JSON.stringify(result));
          if (respDetails && ( respDetails.message == "Success") ) {           
            if ( respDetails.user.role == "admin" ) {
              this.router.navigate(['home']);
            } else if ((respDetails.user.role == "manager") || (respDetails.user.role == "staff")) {
              this.router.navigate(['user'])
            }
          }
        },
        (error) => {
            this.toastr.warning(error, 'Error');
            // alert(err.error.message)
          }
        )
    }
  }
  // addToken=( request: HttpRequest<any>,token: string)=> {
  //   // return request.clone({
  //   //   setHeaders: { Authorization: `Baerer ${token}`}
  //   // })
  //   return request.headers.set('Authorization', 'Bearer ' + token);
  // }
  signupPrss(){
    // this.router.navigate(['/home'])
    this.router.navigate(['/register'])
  }

  generateCode(formData) {
    this.generateCodePress = true;
    if(this.codeGenerateForm.invalid) {
      return;
    } else {
      let emailValue = this.codeGenerateForm.get("registeredEmail").value
      this.userSerivces.generatePasswordResetCode(emailValue)
        .subscribe((result)=> {
          let responseData = JSON.parse(JSON.stringify(result));
          if (responseData && responseData.message){
            // console.log("_____+++++++____",responseData,"+++++++++", responseData.message);
            this.newPasswordField = true;
            this.toastr.info(responseData.message);
          }
        },(err)=> {
          // alert(err)
          this.newPasswordField = false;
          this.toastr.warning(err, 'Error');
        })
    }
  }

  resetPassword(formData) {
    this.resetPasswordSubmit = true;
    if (this.resetPasswordForm.invalid) {
      return
    } else {
      formData.userEmail = this.codeGenerateForm.get("registeredEmail").value;
      // let newPassword = this.resetPasswordForm.get("newPassword").value;
      this.userSerivces.resetPassword(formData)
        .subscribe((result)=> {
          let responseData = JSON.parse(JSON.stringify(result));
          if (responseData && responseData.message){
            this.newPasswordField = true;
            this.toastr.success(responseData.message, 'Success');
            this.closebutton.nativeElement.click();
          }
        },(err)=> {
          this.newPasswordField = false;
          this.toastr.warning(err, 'Error');
        })
    }
  }

}
