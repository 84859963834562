<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h5>Create Objectives & Goals</h5>
                    <h1><b>{{ designationTitle }} - {{ departmentTitle }}</b></h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <a href="#">Home</a>
                        </li>
                        <li class="breadcrumb-item active">Objectives & Goals</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Create Objectives</h3>
                        </div>
                        <!-- /.card-header -->
                        <!-- form start -->
                        <form [formGroup]="registerForm">
                            <div class="card-body">
                                <div class="form-group" >
                                    <label for="Designation">Designation</label>
                                    <input type="text" disabled class="form-control" id="designation" value="{{ designationTitle }}">
                                </div>
                                <div class="form-group">
                                    <label for="Department">Department</label>
                                    <input type="text" disabled class="form-control" id="department" value="{{ departmentTitle }}">
                                </div>
                                <div class="form-group">
                                    <label for="Title">Objective Title</label>
                                    <input formControlName="objectiveTitle"  type="text" class="form-control" id="title" 
                                        [ngClass]="{ 'is-invalid': committed && f.objectiveTitle.errors }" placeholder="Enter Objective">
                                        <div *ngIf="committed && f.objectiveTitle.errors?.required" class="invalid-feedback">Objective title required</div>
                                </div>
                            </div>
                            <!-- /.card-body -->
                        </form>
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary" (click)="saveObjectiveTitle(registerForm)">Submit</button>
                        </div>
                    </div>
                    <!-- /.card -->
                    <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Add Goals</h3>
                        </div>
                        <!-- /.card-header -->
                        <!-- form start -->
                        <!-- <form role="form"> -->
                            <form [formGroup]="addGoalForm">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="objectives">Objectives</label>
                                        <select class="form-control select2" [ngClass]="{ 'is-invalid': submitted && e.objectivesId.errors }"
                                                formControlName="objectivesId" aria-hidden="true">
                                            <option value="" selected>Select Objective</option>
                                            <option *ngFor="let obj of objTitleArray" [ngValue]="obj._id" >{{obj.title}}</option>
                                        </select>
                                        <div *ngIf="submitted && e.objectivesId.errors" class="invalid-feedback">
                                            <div *ngIf="e.objectivesId.errors.required">Objective is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Title">Goal Title</label>
                                        <input formControlName="goalTitle" type="text" class="form-control" id="goalTitle" 
                                                [ngClass]="{ 'is-invalid': submitted && e.goalTitle.errors }" placeholder="Enter Goal Title">
                                            <div *ngIf="submitted && e.goalTitle.errors?.required" class="invalid-feedback">Goal Title required</div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Title">Weightage</label>
                                        <input formControlName="weightage" type="number" class="form-control" id="weightage" 
                                                [ngClass]="{ 'is-invalid': submitted && e.weightage.errors }" placeholder="Weightage in Percentage">
                                            <div *ngIf="submitted && e.weightage.errors?.required" class="invalid-feedback">Weightage required</div>
                                            
                                    </div>
                                </div>
                            </form>

                                <!-- /.card-body -->
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary" (click)="saveGoals(addGoalForm)">Submit</button>
                                </div>

                        <!-- </form> -->
                    </div>
                    <!-- /.card -->
                </div>
                <div class="col-md-8">
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Preview</h3>
                        </div>
                        <div class="col-12 mt-2" id="accordion">
                            <div *ngFor="let obj of objTitleArray;  let i=index" class="card card-primary card-outline">
                                <a class="d-block w-100" data-toggle="collapse" href="#collapseOne" aria-expanded="true">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-8">
                                                <span class="objective" >
                                                    {{i + 1}}. {{obj.title}}
                                                </span>
                                            </div>
                                            <div class="col-2 text-right">
                                                <span class="badge badge-success right"> {{obj.totalScore}}</span>
                                            </div>
                                            <div class="col-2 text-right">
                                                <div class="btn-group btn-group-sm">
                                                    <button (click)="selectUpdtObjective(obj)" class="btn btn-outline-info edit-objective" data-toggle="modal" data-target="#modal-objective" title="Edit">
                                                        <i class="fas fa-edit"></i>
                                                    </button> &nbsp;
                                                    <button (click)="selectDltObjective(obj._id)" class="btn btn-outline-info delete-objective" data-toggle="modal" data-target="#modal-delete" title="Delete">
                                                        <i  class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <table class="table table-hover table-striped">
                                            <tbody>
                                                <tr *ngFor="let goal of obj.goals">
                                                    <td class="mail-star">
                                                        <a href="#">
                                                            <i class="fas fa-star text-warning"></i>
                                                        </a>
                                                    </td>
                                                    <td class="mailbox-subject">
                                                        {{goal.title}}
                                                    </td>
                                                    <td class="mailbox-date text-right">
                                                        <span class="badge badge-info right">{{goal.score}}</span>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="btn-group btn-group-sm">
                                                            <button (click)="clickUpdateGoal(goal, obj._id)" class="btn btn-outline-success" title="Edit" data-toggle="modal" data-target="#modal-goal">
                                                                <i class="fas fa-edit"></i>
                                                            </button> &nbsp;
                                                            <button (click)="clickDeleteGoal(goal._id, obj._id)" class="btn btn-outline-danger" title="Delete" data-toggle="modal" data-target="#modal-goal-delete">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <td class="mailbox-star">
                                                        <a href="#">
                                                            <i class="fas fa-star text-warning"></i>
                                                        </a>
                                                    </td>
                                                    <td class="mailbox-subject">
                                                        Monthly delivery of Billing details to accounts team (month end activity)
                                                    </td>
                                                    <td class="mailbox-date text-right">
                                                        <span class="badge badge-info right">5 %</span>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="btn-group btn-group-sm">
                                                            <button class="btn btn-info" title="Edit" data-toggle="modal" data-target="#modal-goal">
                                                                <i class="fas fa-edit"></i>
                                                            </button>
                                                            <button class="btn btn-danger" title="Delete" data-toggle="modal" data-target="#modal-delete">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.modal-delete starts -->
        <div class="modal fade" id="modal-delete" #closedelete aria-hidden="true" style="display:none">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Objectives</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Do you wish to delete the Objectives ?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">
                            Close
                        </button>
                        <button type="button" class="btn btn-danger" (click)="deleteObjective()" > Delete </button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <div class="modal fade" id="modal-goal-delete" #closebutton aria-hidden="true" style="display:none">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Objective</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Do you wish to delete this Objective ?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">
                            Close
                        </button>
                        <button type="button" class="btn btn-danger" (click)="deleteGoal()" > Delete </button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal-objective starts -->
        <div class="modal fade" id="modal-objective" #closeObjUpdate aria-hidden="true" style="display:none">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form [formGroup]="updateObjForm">
                        <div class="modal-header">
                            <h5 class="modal-title">Update Objective</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="Title">Objective Title</label>
                                <input formControlName="title" type="text" class="form-control" id="title" placeholder="Enter Objective" 
                                    [ngClass]="{ 'is-invalid': updateSubmitted && d.title.errors }">
                                    <div *ngIf="updateSubmitted && d.title.errors?.required" class="invalid-feedback">Title required</div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">
                                Close
                            </button>
                            <button type="button" class="btn btn-primary" (click)="objectiveUpdate(updateObjForm.value)">Save changes</button>
                        </div>
                    </form>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal-objective ends -->
        <!-- /.modal-goals starts -->
        <div class="modal fade" id="modal-goal" #closeGoalUpdate aria-hidden="true" style="display:none">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form [formGroup]="updateGoalForm">
                        <div class="modal-header">
                            <h5 class="modal-title">Update Goals</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="Title">Goals</label>
                                <input formControlName="goaltitle" type="text" class="form-control" id="goaltitle" placeholder="Enter Goals"
                                    [ngClass]="{ 'is-invalid': updateSubmitted && c.goaltitle.errors }" >
                                <div *ngIf="updateSubmitted && c.goaltitle.errors?.required" class="invalid-feedback">Goal Title required</div>
                                
                            </div>
                            <div class="form-group">
                                <label for="Title">Weightage</label>
                                <input formControlName="goalScore" type="number" class="form-control" id="goalScore" 
                                    [ngClass]="{ 'is-invalid': updateSubmitted && c.goalScore.errors }" placeholder="weightage in percentage">
                                <div *ngIf="updateSubmitted && c.goalScore.errors?.required" class="invalid-feedback">Goal Weightage required</div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">
                                Close
                            </button>
                            <button type="button" class="btn btn-primary" (click)="saveUpdatedGoal(updateGoalForm)">Save changes</button>
                        </div>
                    </form>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal-goals ends -->
    </section>
</div>