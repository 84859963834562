import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StarRatingComponent } from 'ng-starrating';

import { ObjectiveService } from 'src/app/services/objective/objective.service';
import { ReviewService } from 'src/app/services/review/review.service';



@Component({
  selector: 'app-final-review',
  templateUrl: './final-review.component.html',
  styleUrls: ['./final-review.component.css']
})
export class FinalReviewComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private objectiveService: ObjectiveService,
    private reviewService: ReviewService
  ) { }

  reviewDetails: any;
  reviewTitle: any;
  reviewDesignation: any;
  reviewDepartment: any;
  objectiveDetails: any;
  objTitleArray: any;
  reviewParamsKey: any;
  datePipe: any;
  startDate: any;
  endDate: any;
  initatedDate: any;
  isReviewCompleted: Boolean;
  selfRatedDate: any;
  managerReviewedDate: any;
  isManagerReviewCompleted: Boolean;
  revieweeComment: any;
  reviewerComment: any;
  totalReviewee_score: any = 0;
  totalReviewer_score: any = 0;
  ngOnInit(): void {
    this.datePipe = new DatePipe('en-US')
    this.route.params.subscribe((params) => {
      this.getIndividualReviews(params.reviewId)
    })

  }


  getIndividualReviews = (reviewId) => {
    this.reviewService.getIndividualReviews(reviewId).subscribe((doc) => {
      let reviewDetails = JSON.parse(JSON.stringify(doc));
      if (reviewDetails.message === "Success") {
        this.reviewDetails = reviewDetails.data;
        this.reviewTitle = this.reviewDetails.review_period_id.title;
        this.reviewDepartment = this.reviewDetails.reviewee.department.title;
        this.reviewDesignation = this.reviewDetails.reviewee.designation.title;
        this.startDate = this.datePipe.transform(this.reviewDetails.review_period_id.start_date);
        this.endDate = this.datePipe.transform(this.reviewDetails.review_period_id.end_date);
        this.initatedDate = this.datePipe.transform(this.reviewDetails.review_period_id.initiate_date);
        this.selfRatedDate = this.datePipe.transform(this.reviewDetails.reviewee_completed_on);
        this.isReviewCompleted = this.reviewDetails.is_reviewee_completed;
        this.isManagerReviewCompleted = this.reviewDetails.is_reviewer_completed;
        this.managerReviewedDate = this.datePipe.transform(this.reviewDetails.reviewer_completed_on);
        this.revieweeComment = this.reviewDetails.reviewee_comment;
        this.reviewerComment = this.reviewDetails.reviewer_comment;
        // console.log(this.revieweeComment)
        // this.currentObjective = this.objectiveDetails._id;
        this.objTitleArray = this.reviewDetails.objectives.length ? this.reviewDetails.objectives : [];
        if (this.objTitleArray.length) {
          this.objTitleArray.forEach((ele) => {
            let total = 0;
            if (ele.goals && ele.goals.length) {
              ele.goals.forEach((goal) => {
                total = total + JSON.parse(goal.score);
                this.totalReviewee_score = this.totalReviewee_score + (goal.reviewee_rating ? (((JSON.parse(goal.reviewee_rating))/5)*JSON.parse(goal.score) ) : this.totalReviewee_score );
                this.totalReviewer_score = this.totalReviewer_score + (goal.reviewer_rating ? (((JSON.parse(goal.reviewer_rating))/5)*JSON.parse(goal.score) ) : this.totalReviewer_score );
              })
            }
            ele.totalScore = total;
          })
        }
      }
    })
  }

  // onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
  //   alert(`Old Value:${$event.oldValue}, 
  //     New Value: ${$event.newValue}, 
  //     Checked Color: ${$event.starRating.checkedcolor}, 
  //     Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  // }


}
