import { Component, OnInit, ViewChild } from '@angular/core';
import { DepartmentService } from 'src/app/services/department/department.service';
import { DesignationService } from 'src/app/services/designation/designation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ObjectiveService } from 'src/app/services/objective/objective.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PrivilegeService } from 'src/app/services/privilege/privilege.service';
import { UserService } from "../../services/user/user.service";
// import { NgSelect2Module } from 'ng-select2';

@Component({
  selector: 'app-list-indicators',
  templateUrl: './list-indicators.component.html',
  styleUrls: ['./list-indicators.component.css']
})
export class ListIndicatorsComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('closedelete') closedelete;
  @ViewChild('closegoals') closegoals;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private departmentServices: DepartmentService,
    private designationServices: DesignationService,
    private objectiveService: ObjectiveService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private privilegeService: PrivilegeService,
    private userSerivces: UserService,

  ) { 
    this.userSubject = JSON.parse(localStorage.getItem('loggedInUser') );
    this.getEditPrivilegeStatus();
    if(this.userSubject.role == 'admin') {
      this.adminUserStatus = true;
    } else {
      this.adminUserStatus = false;
    }
   }

  registerForm: FormGroup;
  importGoalForm: FormGroup;
  designationArray: any
  departmentArray: any
  submitted:Boolean = false;
  objectiveSubmitted:Boolean = false;
  objectivesList: any
  deleteObjectiveId: any;
  importObjectiveId: any;
  completedObjectives: any = [];
  options: any = [];
  userSubject: any;
  managerEditPrivilegeStatus: boolean;
  departmentIdForFiltration: any;
  designationIdForFiltration: any;
  adminUserStatus: boolean = false;



  

  ngOnInit(): void {
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true
    };
    this.options = {
      multiple: true,
      closeOnSelect: false,
      width: '100%',
      dropdownParent:"#modal-assign"
    };
    this.listAllObjectives();
    
    // this.loadAllDesignations();
    this.loadAllDepartments();
    this.registerForm = this.formBuilder.group({
      department: ['', Validators.required],
      designation: ['', Validators.required]
    });

    this.importGoalForm = this.formBuilder.group({
      objective: ['', Validators.required]
    });

  }
  get f() { return this.registerForm.controls; }
  get e() { return this.importGoalForm.controls; }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit() : void {
    var select2Span = document.getElementsByClassName("select2-container--default") as HTMLCollectionOf<HTMLElement>;
    
    
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  // get all objectives to list
  listAllObjectives = ()=> {
    if(this.userSubject.role === 'manager') {
      this.objectiveService.getAllObjectivesByDepartment(this.userSubject.department).subscribe((data)=>{
        this.objectivesList = JSON.parse(JSON.stringify(data)).data;
        if(this.objectivesList && this.objectivesList.length) {
          this.dtTrigger.next();
          this.objectivesList.forEach((ele)=> {
              ele.totalScore = 0;
              if(ele.objectives && ele.objectives.length) {
                  ele.objectives.map((obj)=> {
                      if(obj.goals && obj.goals.length) {
                          obj.goals.map((goal)=> {
                            ele.totalScore = ele.totalScore + JSON.parse(goal.score);
                          })
                      }
                  })
              }
          })
        }
        this.listAllCompletedDesignations();
      })
    } else {
      this.objectiveService.getAllObjectives().subscribe((data)=>{
        this.objectivesList = JSON.parse(JSON.stringify(data)).data;
        if(this.objectivesList && this.objectivesList.length) {
          this.dtTrigger.next();
          this.objectivesList.forEach((ele)=> {
              ele.totalScore = 0;
              if(ele.objectives && ele.objectives.length) {
                  ele.objectives.map((obj)=> {
                      if(obj.goals && obj.goals.length) {
                          obj.goals.map((goal)=> {
                            ele.totalScore = ele.totalScore + JSON.parse(goal.score);
                          })
                      }
                  })
              }
          })
        }
        this.listAllCompletedDesignations();
      })
    }
    
  }

  onChangeDeptFilter = (deptId)=> {
    
    console.log(this.objectivesList);
    
    this.departmentIdForFiltration = deptId.target.value;
    if(deptId.target.value) {
      this.designationServices.departmentDesignations(deptId.target.value).subscribe((data)=>{
        this.designationArray = data;
      });
      this.objectiveService.getAllObjectivesByDepartment(deptId.target.value).subscribe((data)=>{
        this.objectivesList = [];
        this.objectivesList = JSON.parse(JSON.stringify(data)).data;
        this.rerender();
        this.dtTrigger.next();
    
        if(this.objectivesList && this.objectivesList.length) {
         

          this.objectivesList.forEach((ele)=> {
              ele.totalScore = 0;
              if(ele.objectives && ele.objectives.length) {
                  ele.objectives.map((obj)=> {
                      if(obj.goals && obj.goals.length) {
                          obj.goals.map((goal)=> {
                            ele.totalScore = ele.totalScore + JSON.parse(goal.score);
                          })
                      }
                  })
              }
          })
        }
        this.listAllCompletedDesignations();
      });
    } else {
      console.log(this.objectivesList);
      
      this.objectiveService.getAllObjectives().subscribe((data)=>{
        this.objectivesList = [];

        this.objectivesList = JSON.parse(JSON.stringify(data)).data;
    
    console.log(this.objectivesList);
    this.rerender();
    this.dtTrigger.next();
        if(this.objectivesList && this.objectivesList.length) {
        

          this.objectivesList.forEach((ele)=> {
              ele.totalScore = 0;
              if(ele.objectives && ele.objectives.length) {
                  ele.objectives.map((obj)=> {
                      if(obj.goals && obj.goals.length) {
                          obj.goals.map((goal)=> {
                            ele.totalScore = ele.totalScore + JSON.parse(goal.score);
                          })
                      }
                  })
              }
          })
        }
        this.listAllCompletedDesignations();
      })
    }
  }

  onChangeDesignFilter = (designId) => {
    if(designId.target.value) {
      let depAndDesgnIds = {
        'depId' : this.departmentIdForFiltration,
        'designId' : designId.target.value
      };
      this.objectiveService.getAllObjectivesByDepartmentAndDesignation(depAndDesgnIds).subscribe((data)=>{
        this.objectivesList = JSON.parse(JSON.stringify(data)).data;
        this.rerender();
        this.dtTrigger.next();
        if(this.objectivesList && this.objectivesList.length) {
         
          this.objectivesList.forEach((ele)=> {
              ele.totalScore = 0;
              if(ele.objectives && ele.objectives.length) {
                  ele.objectives.map((obj)=> {
                      if(obj.goals && obj.goals.length) {
                          obj.goals.map((goal)=> {
                            ele.totalScore = ele.totalScore + JSON.parse(goal.score);
                          })
                      }
                  })
              }
          })
        }
        this.listAllCompletedDesignations();
      });
    } else if(this.departmentIdForFiltration) {
      
      this.objectiveService.getAllObjectivesByDepartment(this.departmentIdForFiltration).subscribe((data)=>{
        this.objectivesList = JSON.parse(JSON.stringify(data)).data;
        this.rerender();
        this.dtTrigger.next();
        if(this.objectivesList && this.objectivesList.length) {
          
          this.objectivesList.forEach((ele)=> {
              ele.totalScore = 0;
              if(ele.objectives && ele.objectives.length) {
                  ele.objectives.map((obj)=> {
                      if(obj.goals && obj.goals.length) {
                          obj.goals.map((goal)=> {
                            ele.totalScore = ele.totalScore + JSON.parse(goal.score);
                          })
                      }
                  })
              }
          })
        }
        this.listAllCompletedDesignations();
      });
    } else {

      this.designationArray = [];
      this.objectiveService.getAllObjectives().subscribe((data)=>{
        this.objectivesList = JSON.parse(JSON.stringify(data)).data;
         this.rerender();
        this.dtTrigger.next();
        if(this.objectivesList && this.objectivesList.length) {
         
          this.objectivesList.forEach((ele)=> {
              ele.totalScore = 0;
              if(ele.objectives && ele.objectives.length) {
                  ele.objectives.map((obj)=> {
                      if(obj.goals && obj.goals.length) {
                          obj.goals.map((goal)=> {
                            ele.totalScore = ele.totalScore + JSON.parse(goal.score);
                          })
                      }
                  })
              }
          })
        }
        this.listAllCompletedDesignations();
      })
    }

  }

  getEditPrivilegeStatus = () => {
    this.privilegeService.getStaffEditStatus().subscribe((data)=> {
      var editStatus = JSON.parse(JSON.stringify(data));      
      this.managerEditPrivilegeStatus = editStatus.data;
    });
  }

  // loading departments
  loadAllDepartments = ()=> {
    this.departmentServices.getAllDepartments().subscribe((data)=> {
      this.departmentArray = data;
    })
  };
  onChangeDept = (deptId)=> {
    this.designationServices.departmentDesignations(deptId).subscribe((data)=>{
      this.designationArray = data;
    });
  }
  // create object
  createObjective = (data)=> {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    } else if (data.value) {
      this.objectiveService.createObjective(data.value).subscribe((data)=>{
        let responseData = JSON.parse(JSON.stringify(data));
        console.log("hello--",responseData);
        if (responseData.message == "Success"){
          this.closebutton.nativeElement.click();
          this.listAllObjectives()
          this.rerender();
        } else {
          console.log("hello--",responseData.message);
          
          this.toastr.error(responseData.message);
        }
      })
    }
    // this.closebutton.nativeElement.click();
  }
  navigateIndicators = (objectiveId)=> {
    if(this.userSubject.role == 'admin') {
      this.router.navigate([`/indicators-list/${objectiveId}`])
    } else {
      this.router.navigate([`/user/indicators-list/${objectiveId}`])
    }
  }
  navigateCreateGoals = (objectiveId)=> {
    if(this.userSubject.role === 'manager') {
      this.router.navigate([`/user/create-goals/${objectiveId}`])
    } else {
      this.router.navigate([`/create-goals/${objectiveId}`])
    }
  }
  clickDelete = (objectiveId)=> {
    this.deleteObjectiveId = objectiveId;
  }
  deleteObjective= ()=> {
    if(this.deleteObjectiveId) {
      this.objectiveService.deleteFullObjective(this.deleteObjectiveId)
        .subscribe((data)=> {
          this.closedelete.nativeElement.click();
          this.listAllObjectives();
        })
    }
  }

  importGoalTo = (objectiveId) => {
    this.importObjectiveId = objectiveId;
  }

  listAllCompletedDesignations = () => {
    this.objectivesList.map((item: any, index:any) => {

        if(item.totalScore == 100) {
          this.completedObjectives.push({
            value: item._id,
            label: item.department.title + ' -- ' + item.designation.title,
            data: {
              "color": "red",
              "name": item.department.title + ' -- ' + item.designation.title
            },
            id:item._id,

          });
        }      
      
    });
    
  }

  importGoals = (data) => {
    console.log(data.value);
    this.objectiveSubmitted = true;
    if (this.importGoalForm.invalid) {
      return;
    } else if (data.value) {

      let importGoalIds = {
        importFrom: data.value.objective,
        importTo: this.importObjectiveId
      };

      this.objectiveService.importGoals(importGoalIds).subscribe((data)=>{
        let responseData = JSON.parse(JSON.stringify(data));
        if (responseData.message == "Success"){
          this.listAllObjectives()
          this.rerender();
          this.toastr.success(responseData.message, `Successfully imported goals`);
          this.closegoals.nativeElement.click();
        } else {
          this.toastr.error(responseData.message, `Something went wrong`);
        }
      })
    }
  }

}
