<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
        <img src="../../../assets/pictures/wac.png" alt="Webandcrafts" class="brand-image" style="opacity: .8">
        <span class="brand-text font-weight-light">WAC</span>
    </a>
    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar user (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img src="../../../assets/adminlte/dist/img/user.jpeg" class="img-circle elevation-2" alt="User Image">
            </div>
            <div class="info">
                <a href="#" class="d-block">{{userSubject.name ? userSubject.name : "user_name"}}</a>
            </div>
        </div>
        <!-- SidebarSearch Form -->
        <div class="form-inline">
            <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-sidebar">
                        <i class="fas fa-search fa-fw"></i>
                    </button>
                </div>
            </div>
        </div>
        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
                <li class="nav-item">
                    <a href="user" class="nav-link">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            Dashboard
                        </p>
                    </a>
                </li>
                <li *ngIf="userSubject.role == 'manager'" class="nav-item has-treeview menu-open">
                    <a href="#" [ngClass]="{'active': currentURL === '/user/indicators-list'}" class="nav-link">
                        <i class="nav-icon fas fa-bullseye"></i>
                        <p>
                            Objectives
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style="display: block;">
                        <li class="nav-item">
                            <a href="/user/indicators-list" [ngClass]="{'active': currentURL === '/user/indicators-list'}" class="nav-link">
                                <i class="nav-icon fas fa-list-ul"></i>
                                <p>
                                    Objectives List
                                </p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a href="user/indicators" [ngClass]="{'active': currentURL === '/user/indicators'}" class="nav-link">
                        <i class="nav-icon fas fa-chess-pawn"></i>
                        <p>
                            Indicators
                        </p>
                    </a>
                </li>
                <li class="nav-item" *ngIf=" userSubject.isReviewer || (userSubject.role == 'manager') ">
                    <a href="user/members" [ngClass]="{'active': currentURL === '/user/members'}" class="nav-link">
                        <i class="nav-icon fas fa-user-check"></i>
                        <p>
                            Members
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="user/initiated-review" [ngClass]="{'active': currentURL === '/user/initiated-review'}" class="nav-link">
                        <i class="nav-icon fas fa-comments"></i>
                        <p>
                            Reviews
                        </p>
                    </a>
                </li>
                <li class="nav-item" *ngIf="userSubject.role == 'manager' ">
                    <a href="user/reports" [ngClass]="{'active': currentURL === '/user/reports'}" class="nav-link">
                        <i class="nav-icon fas fa-folder"></i>
                        <p>
                            Reports
                        </p>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>