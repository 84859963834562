import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  constructor(
    private http: HttpClient
  ) { }
  
  getAllDesignations(){
    let allDesignations = this.http.get(`${baseUrl}/designation`);
    return allDesignations;
  }

  departmentDesignations(deptId){
    const params = new HttpParams()
      .set('departmentId', deptId) 
    let allDesignations = this.http.get(`${baseUrl}/designation/filter`, { params: params } );
    return allDesignations;
  }

  filterByOptions(filterData) {
    const params = new HttpParams()
    .set('filterData', filterData) 
    let departmentWiseData = this.http.get(`${baseUrl}/designation/filter-by-options?departmentId=${filterData?.departmentId}&designationId=${filterData?.designationId}&reportingToId=${filterData?.reportingToId}&changeDesignationListStatus=${filterData?.changeDesignationListStatus}`, );    
    return departmentWiseData;
  }

  createDesignations(data){
    let createDesignations = this.http.post(`${baseUrl}/designation`, data);
    return createDesignations;
  }

  editDesignations(data){
    let createDesignations = this.http.put(`${baseUrl}/designation/edit`, data);
    return createDesignations;
  }

  deleteDesignation(data){
    console.log(data);
    let createDesignations = this.http.put(`${baseUrl}/designation/delete`, data);
    return createDesignations;
  }

}
