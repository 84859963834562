import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinalReviewComponent } from './final-review.component';
import { BrowserModule } from '@angular/platform-browser';
import { RatingModule } from 'ng-starrating';


@NgModule({
  declarations: [
    FinalReviewComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RatingModule
  ]
})
export class FinalReviewModule { }
