import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-sidebar',
  templateUrl: './member-sidebar.component.html',
  styles: []
})
export class MemberSidebarComponent implements OnInit {
  userSubject: any;
  currentURL: any;

  constructor( ) {
    this.userSubject = JSON.parse(localStorage.getItem('loggedInUser') );
   }

  ngOnInit(): void {
    this.currentURL = window.location.pathname;
  }

}
