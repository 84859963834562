import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'NotFound',
  templateUrl: './NotFound.component.html',
  styleUrls: ['./NotFound.component.css']
})
export class NotFoundComponent implements OnInit {

 

  constructor(
  
  ) { }

  submitted: Boolean = false;

  ngOnInit(): void {

    

  }




}
