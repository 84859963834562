<div class="content-wrapper">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Reviews</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <a href="#">Home</a>
                        </li>
                        <li class="breadcrumb-item active">Reviews</li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
    <section class="content">
        <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Review Period</h3>
                <div class="card-tools">
                    <div class="btn-group">
                        <!-- <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-calender">
                            <i class="far fa-calendar-check"></i>
                            Start Review
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="card-body">

                <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4">
                    <div class="row mb-2">
                        <div class="col-sm-12 col-md-12 text-right">
                            <div class="dt-buttons btn-group flex-wrap">
                               </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped projects">
                            <thead>
                                <tr>
                                    <th style="width: 1%">
                                        #
                                    </th>
                                    <th style="width: 20%">
                                        Review Period
                                    </th>
                                    <th class="text-center">
                                        Total Members
                                    </th>
                                    <th class="text-center">
                                        Members Reviewed
                                    </th>
                                    <th class="text-center">
                                        Status
                                    </th>
                                    <th class="text-center" style="width: 20%">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let review of allReviewPeriods" >
                                    <td>
                                        #
                                    </td>
                                    <td>
                                        <a>
                                            {{review.title}}
                                        </a>
                                        <br>
                                        <small>
                                            {{review.start_date | date: "MMM-d-y"}} - {{review.end_date | date: "MMM-d-y"}}
                                        </small>
                                    </td>
                                    <td class="project-state text-align">
                                        {{ review.total_members }}
                                    </td>
                                    <td class="project-state text-align">
                                        {{ review.reviewed_members }}
                                    </td>
                                    <td class="project-state text-align">
                                        <span *ngIf="review.review_status === 'PENDING'" class="badge badge-warning"> {{review.review_status}} </span>
                                        <span *ngIf="review.review_status === 'INITIATED'" class="badge badge-info"> {{review.review_status}} </span>
                                        <span *ngIf="review.review_status === 'COMPLETED'" class="badge badge-success"> {{review.review_status}} </span>
                                    </td>
                                    <td class="project-actions text-center">
                                        <div class="btn-group" *ngIf="review.is_reviewer_completed">
                                            <button type="button" (click)="clickedViewStatus(review.reviewId)" class="btn btn-info">
                                                <i class="far fa-calendar-check"></i>
                                                View Status
                                            </button>
                                        </div>
                                        <div class="btn-group" *ngIf="review.review_status === 'INITIATED' && !review.is_reviewer_completed">
                                            <button type="button" (click)="selfReviewClicked(review.reviewId)" class="btn btn-info">
                                                <i class="fas fa-pencil-alt"></i>
                                                Self Review
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
               
            </div>
        </div>
        <!-- /.card -->
    </section>
</div>